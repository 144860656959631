import * as Objects from 'Helper/object/Object';

/**
 * @deprecated
 * Extend Slot, Lifecycle, etc. entities instead or create a new one.
 * Use globalThis.Baxter.state as storage.
 */
export class ConfigurationService {
  constructor(private config) {}

  getById = (config, pageId, containerId, slotId) => {
    if (config == null || typeof config !== 'object') return null;
    const slotKey = `${pageId}#${containerId}#${slotId}`;
    const groupKey = Object.keys(config).find((setting) => setting.split(',').includes(slotKey));
    const settingsDefaults = config?._ || {};
    const settingsDefault = settingsDefaults?._;
    const settingsPage = settingsDefaults[pageId];
    const settingsSlot = config[groupKey!];

    if (
      Objects.isObject(settingsDefault, true) ||
      Objects.isObject(settingsPage, true) ||
      Objects.isObject(settingsSlot, true)
    ) {
      return Objects.merge(settingsDefault || {}, settingsPage || {}, settingsSlot || {});
    }
    if (settingsSlot != null) return settingsSlot;
    if (settingsPage != null) return settingsPage;
    if (settingsDefault != null) return settingsDefault;
  };

  hasTargetingFor(providerId) {
    const slotProviderSettings = this.config.slots?.providerSettings[providerId] || {};
    return slotProviderSettings.targeting !== undefined && slotProviderSettings.targeting != null;
  }

  getTargetingMapFor(providerId, pageId, containerId, slotId) {
    const targetingSetting = this.config.slots?.providerSettings[providerId].targeting;
    const targeting = this.getById(targetingSetting, pageId, containerId, slotId) || {};
    return targeting.map || [];
  }

  hasVariablesFor(providerId) {
    const slotProviderSettings = this.config.slots?.providerSettings[providerId] || {};
    return slotProviderSettings.variables !== undefined && slotProviderSettings.variables != null;
  }

  getVariablesMapFor(providerId, pageId, containerId, slotId) {
    const variablesSetting = this.config.slots?.providerSettings[providerId].variables;
    const variables = this.getById(variablesSetting, pageId, containerId, slotId) || {};
    return variables.map || [];
  }

  hasPathFor(providerId) {
    const slotProviderSettings = this.config.slots?.providerSettings[providerId] || {};
    return slotProviderSettings.path !== undefined && slotProviderSettings.path != null;
  }

  getPathMapFor(providerId, pageId, containerId, slotId) {
    const pathSettings = this.config.slots?.providerSettings[providerId].path;
    const path = this.getById(pathSettings, pageId, containerId, slotId) || {};
    return path.map || [];
  }

  getProviderAccountId(id) {
    const providerGeneralSettings = Baxter.config.providers[id]?.settings || {};
    return providerGeneralSettings.accountId;
  }

  getSlotLazyLoad(pageId, containerId, slotId) {
    const lazyLoadConfig = this.config?.slots?.settings?.lazyLoad;
    return this.getById(lazyLoadConfig, pageId, containerId, slotId) || {};
  }

  getTimeRefresh(pageId, containerId, slotId) {
    const lazyLoadConfig = this.config?.slots?.settings?.timerRefresh;
    return this.getById(lazyLoadConfig, pageId, containerId, slotId) || {};
  }

  isStickyEnabledForSlot(pageId, containerId, slotId) {
    return this.getStickyConfiguration(pageId, containerId, slotId).enabled;
  }

  getStickyConfiguration(pageId, containerId, slotId) {
    const config = this.config?.slots?.settings?.sticky;
    return this.getById(config, pageId, containerId, slotId) || {};
  }

  getSlotProvider(pageId, containerId, slotId) {
    const provider = this.config?.slots?.provider;
    return this.getById(provider, pageId, containerId, slotId) || {};
  }

  getVariables(pageId, containerId, slotId) {
    const variablesConfig = this.config?.slots.providerSettings.client.variables;
    const variables = this.getById(variablesConfig, pageId, containerId, slotId)?.map || [];
    const reduced = variables
      .filter((variable) => !!variable.value)
      // eslint-disable-next-line no-return-assign,no-sequences
      .reduce((obj, variable) => ((obj[variable.key] = variable.value), obj), {});
    return reduced || [];
  }

  getRuleCondition(pageId, containerId, slotId) {
    const rules = this.config?.slots.settings.rule;
    const rule = this.getById(rules, pageId, containerId, slotId) || {};
    return rule?.condition;
  }

  getPageIds() {
    return Object.keys(this.config?.containers || {});
  }

  getContainerByPageId(pageId) {
    const containers = this.config?.containers;
    return containers?.[pageId] || [];
  }

  isFallbackEnabledFor(pageId, containerId) {
    return (
      this.getContainerByPageId(pageId).find((container) => container.id === containerId)?.fallbackEnabled || false
    );
  }

  getSlotsForContainer(pageId, containerId) {
    return this.getContainerByPageId(pageId)
      .filter((container) => container.id === containerId)
      .flatMap((container) => container.slots);
  }

  getPlaceholder(pageId, containerId, placeholderId) {
    return (
      this.config.containers?.[pageId]
        ?.find((c) => c.id === containerId)
        ?.placeholders?.map?.find((p) => p.id === placeholderId) || {}
    );
  }
}
