import * as Html from 'Helper/browser/Html';

export const webpackExclude = (config) => !config.app?.scripts;

export const bootstrap = () => {
  const { appId } = globalThis.Baxter.config;
  const { scripts } = globalThis.Baxter.config?.app || {};

  scripts.forEach((script, i) => {
    Html.addScriptToHead(`baxter-script${i}-${appId}`, script.path, script.attributes);
  });
};

export default {
  bootstrap,
};
