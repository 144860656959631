import { ConfigurationService } from 'Domain/version/web/configuration/ConfigurationService';
import { SlotForContainerFinder } from 'Domain/version/web/core/services/SlotForContainerFinder';
import { ContainerParamsRepository } from 'Domain/version/web/core/repositories/inmemoroy/ContainerParamsRepository';
import { SlotContainerRepository } from 'Domain/version/web/core/repositories/inmemoroy/SlotContainerRepository';
import { SlotRenderedHandler } from 'Domain/version/web/core/services/SlotRenderedHandler';
import { FillContainerMetrics } from 'Domain/version/web/core/services/FillContainerMetrics';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';

export class BaxterContext {
  public configurationService: ConfigurationService;

  public containersParamsRepository: ContainerParamsRepository;

  public slotContainerRepository: SlotContainerRepository;

  public fillContainerMetrics: FillContainerMetrics;

  public slotForContainerFinder: SlotForContainerFinder;

  public slotRenderedHandler: SlotRenderedHandler;

  constructor(config) {
    this.configurationService = new ConfigurationService(config);
    this.containersParamsRepository = new ContainerParamsRepository();
    this.slotContainerRepository = new SlotContainerRepository();
    this.fillContainerMetrics = new FillContainerMetrics(this.configurationService, newRelicMetrics);
    this.slotForContainerFinder = new SlotForContainerFinder(
      this.configurationService,
      this.containersParamsRepository,
      this.slotContainerRepository,
      this.fillContainerMetrics
    );
    this.slotRenderedHandler = new SlotRenderedHandler(
      this.configurationService,
      this.slotForContainerFinder,
      this.containersParamsRepository,
      this.fillContainerMetrics
    );
  }
}
