import * as Html from 'Helper/browser/Html';

export const webpackExclude = (config) => !config.app?.adblock?.enabled;

const bootstrap = () => {
  Html.addScriptToHead(`baxter-script-blockthrough`, 'https://btloader.com/tag?o=5162929816076288&upapi=true');
};

export default {
  bootstrap,
};
