import * as Html from 'Helper/browser/Html';
import * as StickyCloseButton from 'Domain/version/web/feature/StickyCloseButton';
import { Config } from 'Types/Config';
import { Slot, StickySlot } from 'Types/Slot';
import { Features } from 'Domain/version/web/config/Features';
import * as State from 'Domain/version/web/core/State';
import { Observers } from 'Domain/version/web/config/Observers';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';

export const id = Features.STICKY;

export const webpackExclude = (config: Config) => {
  const settings = config.slots?.settings?.sticky;
  return !(
    (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
    (settings && Object.values(settings).some((item) => !!item?.enabled))
  );
};

const isEnabledForSlot = (pageId: string, containerId: string, slotId: string): boolean =>
  globalThis.Baxter.context.configurationService.isStickyEnabledForSlot(pageId, containerId, slotId);

const transform = (pageId: string, containerId: string, slotId: string): StickySlot => {
  console.info('[SLOTS][STICKY][TRANSFORM]');
  const slot = {
    [id]: {
      state: {
        sticky: false,
      },
    },
  } as StickySlot;
  slot[id].config = globalThis.Baxter.context.configurationService.getStickyConfiguration(pageId, containerId, slotId);
  return slot;
};

export const unstick = (slot: Slot, htmlElement: HTMLElement) => {
  console.info('[SLOTS][STICKY][UNSTICK]');
  newRelicMetrics.reportMetric(BaxterMetric.STICKY_UNSTICK, {});
  // eslint-disable-next-line no-param-reassign
  slot[id].state.sticky = false;

  setTimeout(() => {
    try {
      console.info('[SLOTS][STICKY][REMOVECLOSEBUTTON]');
      const closeButton = document.getElementById(`${slot.innerId}-sticky-close-button`);
      if (closeButton) {
        closeButton.remove();
      }
    } catch (e) {
      console.error('[SLOTS][STICKY][REMOVECLOSEBUTTON][TIMEOUT]', e);
      newRelicMetrics.reportError(BaxterError.STICKY_REMOVE_CLOSE_BUTTON_TIMEOUT_ERROR, {
        message: (e as Error).message,
      });
    }
  }, 50);

  Html.addStyleToElement(htmlElement, { cssText: slot[id].state.oldStyle });
  const stickyPlaceholder = document.getElementById(`${slot.innerId}-sticky-placeholder`);
  if (stickyPlaceholder) {
    // eslint-disable-next-line no-param-reassign
    htmlElement.classList.value = stickyPlaceholder.classList.value;
    stickyPlaceholder.remove();
  }
};

const addCloseButton = (slot: StickySlot, htmlElement: HTMLElement) => {
  console.info('[SLOTS][STICKY][ADDCLOSEBUTTON]');
  const closeButton = document.createElement('div');
  closeButton.id = `${slot.innerId}-sticky-close-button`;
  closeButton.innerHTML = slot[id].config.closeButton.label || '&times;';
  Html.addClass(closeButton, 'baxter-inner-sticky-close-button');
  Html.addStyleToElement(
    closeButton,
    {
      cssText:
        slot[id].config.closeButton.style ||
        'cursor: pointer; opacity: 0.3; font-size: 25px; font-weight: 400; height: 30px;',
    },
    false
  );
  const closeButtonOldOpacity = closeButton.style.opacity;
  Html.addStyleToElement(
    closeButton,
    {
      opacity: 0,
    },
    false
  );
  htmlElement.parentNode?.insertBefore(closeButton, htmlElement);
  const rightLeftBottomTop = StickyCloseButton.calculateRightLeftBottomTop(
    slot[id].config.anchor,
    slot[id].config.closeButton.anchor || 'top-right',
    htmlElement.getBoundingClientRect(),
    closeButton.getBoundingClientRect()
  );
  Html.addStyleToElement(closeButton, rightLeftBottomTop, true);
  Html.addStyleToElement(closeButton, { opacity: closeButtonOldOpacity }, false);
  closeButton.addEventListener('click', () => {
    try {
      console.info('[SLOTS][STICKY][CLOSEBUTTONEVENTLISTENER]');
      newRelicMetrics.reportMetric(BaxterMetric.STICKY_CLOSE, {});
      // eslint-disable-next-line no-param-reassign
      slot[id].state.stickyClosedByUser = true;
      unstick(slot, htmlElement);
    } catch (e) {
      console.error('[SLOTS][STICKY][CLOSEBUTTONEVENTLISTENER]', e);
      newRelicMetrics.reportError(BaxterError.STICKY_CLOSE_BUTTON_EVENT_LISTINER_ERROR, {
        message: (e as Error).message,
      });
    }
  });
  if (!slot[id].state.resizeListenerAdded) {
    // eslint-disable-next-line no-param-reassign
    slot[id].state.resizeListenerAdded = true;
    const resizeObserver = new ResizeObserver(() => {
      try {
        console.info('[SLOTS][STICKY][RESIZEOBSERVER]');
        const closeButtonElement = Html.getElementById(`${slot.innerId}-sticky-close-button`);
        if (closeButtonElement) {
          const rightLeftBottomTopOnResize = StickyCloseButton.calculateRightLeftBottomTop(
            slot[id].config.anchor,
            slot[id].config.closeButton?.anchor || 'top-right',
            htmlElement.getBoundingClientRect(),
            closeButtonElement.getBoundingClientRect()
          );
          console.debug(
            '[SLOTS][STICKY][RESIZEOBSERVER] Html.addStyleToElement',
            closeButtonElement,
            rightLeftBottomTopOnResize,
            true
          );
          Html.addStyleToElement(closeButtonElement, rightLeftBottomTopOnResize, true);
        }
      } catch (e) {
        console.error('[SLOTS][STICKY][RESIZEOBSERVER]', e);
        newRelicMetrics.reportError(BaxterError.STICKY_RESIZE_OBSERVER_ERROR, { message: (e as Error).message });
      }
    });
    resizeObserver.observe(htmlElement);
  }
};

const addSlotPlaceholder = (slot: StickySlot, htmlElement: HTMLElement) => {
  const boundingRect = htmlElement.getBoundingClientRect();
  const currentWidth = boundingRect.width;
  const currentHeight = boundingRect.height;
  const slotPlaceholderWhileSticky = document.createElement('div');
  slotPlaceholderWhileSticky.id = `${slot.innerId}-sticky-placeholder`;
  slotPlaceholderWhileSticky.style.width = `${currentWidth}px`;
  slotPlaceholderWhileSticky.style.height = `${currentHeight}px`;
  slotPlaceholderWhileSticky.classList.value = htmlElement.classList.value;
  htmlElement.parentNode?.insertBefore(slotPlaceholderWhileSticky, htmlElement);
};

const moveSlot = (slot: StickySlot, htmlElement: HTMLElement) => {
  // eslint-disable-next-line no-param-reassign
  slot[id].state.oldStyle = htmlElement.style.cssText;
  let newStyle = slot[id].config.style;
  const props =
    slot[id].config.anchor.value === 'top' ? ['top', 'left', 'right'] : slot[id].config.anchor.value.split('-');
  props.forEach((prop) => {
    newStyle += ` ${prop}: ${slot[id].config.anchor[prop]}px;`;
  });
  // eslint-disable-next-line no-param-reassign
  htmlElement.classList.value = 'baxter-inner-sticky';
  Html.addStyleToElement(htmlElement, { cssText: newStyle }, false);
};

export const stick = (slot: StickySlot, htmlElement: HTMLElement) => {
  console.info('[SLOTS][STICKY][STICK]');
  newRelicMetrics.reportMetric(BaxterMetric.STICKY_STICK, {});
  // eslint-disable-next-line no-param-reassign
  slot[id].state.sticky = true;

  addSlotPlaceholder(slot, htmlElement);

  moveSlot(slot, htmlElement);

  setTimeout(() => {
    try {
      addCloseButton(slot, htmlElement);
    } catch (e) {
      console.error('[SLOTS][STICKY][ADDCLOSEBUTTON][TIMEOUT]', e);
      newRelicMetrics.reportError(BaxterError.STICKY_ADD_CLOSE_BUTTON_TIMEOUT_ERROR, { message: (e as Error).message });
    }
  }, 50);
};

const onIntersection = (slot: StickySlot, element: IntersectionObserverEntry) => {
  // eslint-disable-next-line no-param-reassign
  slot[id].state.previousTop = slot[id].state.previousTop || 0;
  // eslint-disable-next-line no-param-reassign
  slot[id].state.currentTop = element.boundingClientRect.top;
  const htmlElement = Html.getElementById(slot.innerId);
  if (htmlElement && !slot[id].state.stickyClosedByUser) {
    if (
      !slot[id].state.sticky &&
      htmlElement.getBoundingClientRect().height > 0 &&
      slot[id].state.currentTop < 0 &&
      element.intersectionRatio < 0.6 &&
      slot[id].state.currentTop < slot[id].state.previousTop
    ) {
      stick(slot, htmlElement);
    } else if (
      slot[id].state.sticky &&
      element.intersectionRatio > 0.6 &&
      slot[id].state.currentTop > slot[id].state.previousTop
    ) {
      unstick(slot, htmlElement);
    }
  }
  // eslint-disable-next-line no-param-reassign
  slot[id].state.previousTop = slot[id].state.currentTop;
};

const onResize = (slot: StickySlot, element: ResizeObserverEntry) => {
  const htmlElement = Html.getElementById(slot.innerId);
  if (htmlElement && !slot[id].state.stickyClosedByUser) {
    const boundingRect = htmlElement.getBoundingClientRect();
    if (
      !slot[id].state.sticky &&
      boundingRect.height > 0 &&
      boundingRect.top < 0 &&
      -boundingRect.top > element.contentRect.height / 2
    ) {
      stick(slot, htmlElement);
    }
  }
};

const addContainerObservers = (containerId: string) => {
  console.info('[SLOTS][STICKY][ADDCONTAINEROBSERVERS]');
  State.addElementObserver(containerId, Observers.intersectionSticky, {
    threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  });
  State.addElementObserver(containerId, Observers.resizeSticky);
};

const remove = (slot: StickySlot): void => {
  console.info('[SLOTS][STICKY][REMOVE]');
  State.removeElementObserver(Observers.intersectionSticky);
  State.removeElementObserver(Observers.resizeSticky);
  if (slot[id].state.sticky) {
    const htmlElement = Html.getElementById(slot.innerId);
    if (htmlElement) {
      unstick(slot, htmlElement);
    }
  }
};

export default {
  id,
  isEnabledForSlot,
  onIntersection,
  onResize,
  transform,
  addContainerObservers,
  remove,
};
