import * as Slot from 'Domain/version/web/core/Slot';
import * as State from 'Domain/version/web/core/State';
import * as Event from 'Domain/version/web/core/Event';
import * as Html from 'Helper/browser/Html';
import { Config } from 'Types/Config';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';

export const webpackExclude = (config: Config) => {
  const settings = config.slots?.settings?.lazyLoad;
  return !(
    (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
    (settings && Object.values(settings).some((item) => !!item?.enabled))
  );
};

const getSettings = (pageId, containerId, slotId) =>
  globalThis.Baxter.context.configurationService.getSlotLazyLoad(pageId, containerId, slotId);

const isEnabledForSlot = (pageId, containerId, slotId) => {
  const result = getSettings(pageId, containerId, slotId);
  return result && result.enabled;
};

const isVisibleType = (pageId, containerId, slotId) => {
  const result = getSettings(pageId, containerId, slotId);
  return result && result.enabled && result.type === 'visible';
};

const isEventType = (pageId, containerId, slotId) => {
  const result = getSettings(pageId, containerId, slotId);
  return result && result.enabled && result.type === 'event';
};

const isPreRequest = (pageId, containerId, slotId) => {
  const settings = getSettings(pageId, containerId, slotId);
  return settings && settings.enabled && settings.preRequest;
};

const getEventName = (pageId, containerId, slotId) => {
  const result = getSettings(pageId, containerId, slotId);
  if (result && result.enabled) {
    return result.eventName;
  }
};

const loadSlot = async (pageId, containerId, slotId) => {
  console.info('[SLOTS][LAZYLOAD][LOADSLOT]', pageId, containerId, slotId);
  const slot = State.getSlot(containerId) || {};
  await Slot.set(containerId, slot.params, false);
  const container = Html.getElementById(containerId);
  if (container) {
    const eventName = getEventName(pageId, containerId, slotId);
    container.dispatchEvent(new CustomEvent(eventName));
  }
};

const addSlotEventListener = async (pageId, containerId, slotId) => {
  const container = Html.getElementById(containerId);
  const eventName = getEventName(pageId, containerId, slotId);
  if (container && eventName) {
    const setSlot = async () => {
      try {
        console.info('[SLOTS][LAZYLOAD][SLOTEVENTLISTENER]');
        Event.removeListener(eventName, container, setSlot);
        const slot = State.getSlot(containerId) || {};
        console.debug('[SLOTS][LAZYLOAD][SLOTEVENTLISTENER] Slot.set', containerId, slot.params);
        await Slot.set(containerId, slot.params, false);
      } catch (e) {
        console.error('[SLOTS][LAZYLOAD][SLOTEVENTLISTENER]', e);
        newRelicMetrics.reportError(BaxterError.LAZY_LOAD_SET_SLOT_EVENT_LISTENER_ERROR, {
          message: (e as Error).message,
        });
      }
    };
    Event.removeListener(eventName, container, setSlot);
    Event.addListener(eventName, container, setSlot);
  }
};

export default {
  getSettings,
  isEnabledForSlot,
  loadSlot,
  getEventName,
  isPreRequest,
  isVisibleType,
  isEventType,
  addSlotEventListener,
};
