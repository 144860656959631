import Objects from 'Helper/object/Object';
import { Constants } from './Constants';
import ConditionCheckers from './ConditionCheckers';

const { NOT_INCLUDES, IS_NOT, INCLUDES, IS, GT, LT, GTE, LTE, CONTAINER, DATE } = Constants;

const possibleOperatorInOrder = [NOT_INCLUDES, IS_NOT, INCLUDES, IS, GT, LT, GTE, LTE, CONTAINER];

const findOperator = (condition) => possibleOperatorInOrder.find((operator) => condition.includes(` ${operator} `));

const checkerMap = {
  [INCLUDES]: ConditionCheckers.checkInclusion,
  [NOT_INCLUDES]: ConditionCheckers.checkExclusion,
  [IS]: ConditionCheckers.checkEquality,
  [IS_NOT]: ConditionCheckers.checkInequality,
  [GT]: ConditionCheckers.checkIfGreaterThan,
  [LT]: ConditionCheckers.checkIfLessThan,
  [GTE]: ConditionCheckers.checkIfGreaterThanOrEqual,
  [LTE]: ConditionCheckers.checkIfLessThanOrEqual,
  [CONTAINER]: ConditionCheckers.checkContainer,
};

const evaluateCondition = (objectToCheck, condition) => {
  const operator = findOperator(condition);

  const [propertyPath, lookupValue] = condition.split(` ${operator} `).map((sides) => sides.trim());

  const valueOfProperty =
    (propertyPath === DATE ? propertyPath : undefined) ||
    (operator === CONTAINER ? propertyPath : undefined) ||
    Objects.get(objectToCheck, propertyPath);

  if (!operator) {
    throw new Error(
      `[SLOTS] No operator found in condition: "${condition}". Please use on of the followings: '${possibleOperatorInOrder.join("', '")}'`
    );
  }

  if (!lookupValue) {
    throw new Error(`[SLOTS] You haven't specified what to look for in condition: "${condition}"`);
  }

  return !!operator && !!lookupValue && checkerMap[operator](valueOfProperty, lookupValue);
};

export default (objectToCheck, condition) => {
  // if it is not already evaluated portion of a condition, so it is not a boolean yet
  if (typeof condition === 'string') {
    return evaluateCondition(objectToCheck, condition);
  }
  if (typeof condition === 'boolean') {
    return condition;
  }
  return false;
};
