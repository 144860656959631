import * as Cookie from 'Helper/browser/Cookie';
import * as Html from 'Helper/browser/Html';
import * as UUID from 'Helper/string/UUID';
import * as Objects from 'Helper/object/Object';
import * as LifeCycle from 'Domain/version/web/core/Lifecycle';
import * as Preview from 'Domain/version/web/core/Preview';
import { Observers } from 'Domain/version/web/config/Observers';
import { Slot } from 'Types/Slot';
import { TargetingParams } from 'Types/TargetingParams';
import { Interstitials } from 'Types/State';
import { DesktopTypes } from 'Domain/version/web/config/DesktopTypes';
import { Config } from 'Types/Config';

export const setProviders = () => {
  const providerConfig = globalThis.Baxter.config.slots?.provider || {};
  const providerState = globalThis.Baxter.state.providers;
  const addToState = (configNode: Config['slots']['provider']) => {
    Object.keys(configNode || {}).forEach((slotKey) => {
      const value = configNode?.[slotKey] || {};
      if (typeof value === 'string') {
        if (!providerState.includes(value)) {
          providerState.push(value);
        }
      } else {
        // @ts-ignore
        addToState(value);
      }
    });
  };

  // @ts-ignore
  addToState(providerConfig);
};

export const setPageId = (pageId: string) => {
  globalThis.Baxter.state.page.id = pageId;
};

export const getPageId = () => globalThis.Baxter.state.page.id;

export const setPageParams = (pageParams: TargetingParams) => {
  globalThis.Baxter.state.page.params = pageParams;
};

export const setPage = (pageId: string, params: TargetingParams) => {
  setPageParams(params);
  setPageId(pageId);
};

export const getPageParams = () => globalThis.Baxter.state.page.params;

export const getSlots = (): Record<string, Slot> => globalThis.Baxter.state.slots;

export const setSlots = (slots: Record<string, Slot> = {}) => {
  globalThis.Baxter.state.slots = slots;
};

export const setInterstitialSlot = (interstitialId: string, slot: Slot) => {
  if (globalThis.Baxter.state.interstitials) globalThis.Baxter.state.interstitials[interstitialId] = slot;
  else globalThis.Baxter.state.interstitials = { actions: [], [interstitialId]: slot } as Interstitials;
};

export const getInterstitialSlot = (interstitialId: string) => globalThis.Baxter.state.interstitials?.[interstitialId];

export const removeInterstitialSlot = (interstitialId: string) => {
  delete globalThis.Baxter.state.interstitials?.[interstitialId];
};

export const setInterstitialAction = (action: () => Promise<void>) => {
  globalThis.Baxter.state.interstitials?.actions?.push(action);
};

export const getInterstitialActions = () => globalThis.Baxter.state.interstitials?.actions || [];

export const clearInterstitialActions = () => {
  if (globalThis.Baxter.state.interstitials?.actions?.length) {
    globalThis.Baxter.state.interstitials.actions = [];
  }
};

export const getSlot = (containerId: string): Slot => globalThis.Baxter.state.slots[containerId];

export const setSlot = (containerId: string, slot: Slot) => {
  globalThis.Baxter.state.slots[containerId] = slot;
};

export const removeSlot = (containerId: string) => {
  delete globalThis.Baxter.state.slots[containerId];
};

export const setUserId = () => {
  globalThis.Baxter.state.user.id = Cookie.get('dfp_user_id') || `${UUID.v4()}-ver2`;
};

export const getUserId = () => globalThis.Baxter.state.user.id;

export const setSessionLong = () => {
  const cookieValue = Cookie.get('onap');
  const [sessionLong] = cookieValue?.split('-') || [];
  globalThis.Baxter.state.user.sessionLong = sessionLong;
};

export const getSessionLong = () => globalThis.Baxter.state.user.sessionLong;

export const setUserActive = async (active = true) => {
  if (globalThis.Baxter.state.user.active !== active) {
    console.debug('[SLOTS][STATE][SETUSERACTIVE]', active);
    globalThis.Baxter.state.user.active = active;
    await LifeCycle.onActiveChanged();
  }
};

export const getUserActive = () => globalThis.Baxter.state.user.active;

export const setUserConsent = (userConsent = true) => {
  globalThis.Baxter.state.user.consent = userConsent;
};

export const getUserConsent = () => globalThis.Baxter.state.user.consent;

export const removeElementObserver = (observerId: string) => {
  const { observers } = globalThis.Baxter.state.user;
  if (observers && observers[observerId]) {
    const observer = observers[observerId];
    observer.disconnect();
    delete observers[observerId];
  }
};

export const setGeneralObserver = () => {
  removeElementObserver(Observers.intersectionGeneral);
  const observer = new IntersectionObserver(LifeCycle.onContainerIntersection(Observers.intersectionGeneral));
  Objects.set(globalThis.Baxter.state.user.observers, Observers.intersectionGeneral, observer);
};

export const getObserver = (observerType: Observers) => globalThis.Baxter.state.user.observers[observerType];

export const addGeneralObserver = (elementId: string) => {
  const element = Html.getElementById(elementId);
  if (element) {
    getObserver(Observers.intersectionGeneral).observe(element);
  }
};

export const removeGeneralObserver = (elementId: string) => {
  const element = Html.getElementById(elementId);
  if (element) {
    getObserver(Observers.intersectionGeneral).unobserve(element);
  }
};

export const addElementObserver = (elementId: string, observerName: Observers, options?: unknown) => {
  const observerId = `${elementId}-${observerName}`;
  removeElementObserver(observerId);
  const element = Html.getElementById(elementId);
  if (element) {
    let observer: IntersectionObserver | ResizeObserver;
    switch (observerName) {
      case Observers.intersectionLazyLoad:
      case Observers.intersectionSticky:
      case Observers.intersectionAutoplay:
      case Observers.intersectionGeneral:
        observer = new IntersectionObserver(
          LifeCycle.onContainerIntersection(observerName),
          options as IntersectionObserverInit | undefined
        );
        break;
      case Observers.resizeSticky:
      case Observers.resizeAutoplay:
        observer = new ResizeObserver(LifeCycle.onContainerResize(observerName));
        break;
      default:
        console.error(`[SLOTS][STATE][ADDELEMENTOBSERVER] unsupported observer type: ${observerName}`);
        throw new Error(`[SLOTS][STATE][ADDELEMENTOBSERVER] unsupported observer type: ${observerName}`);
    }
    observer.observe(element);
    Objects.set(globalThis.Baxter.state.user.observers, observerId, observer);
  }
};

export const removeElementObservers = (elementId: string) => {
  Object.values(Observers)
    .filter((type) => type !== Observers.intersectionGeneral)
    .forEach((observerType) => {
      const observerName = `${elementId}-${observerType}`;
      removeElementObserver(observerName);
    });
};

export const getContainerTimer = (entityId: string, timerName: string) =>
  globalThis.Baxter.state.user.timers.containers[entityId]?.[timerName];

export const removeContainerTimer = (containerId: string, timerName: string) => {
  const timer = getContainerTimer(containerId, timerName);
  if (timerName && timer) {
    clearTimeout(timer);
    const timers = globalThis.Baxter.state.user.timers.containers;
    if (timers[containerId]?.[timerName]) {
      delete timers[containerId][timerName];
      if (!Object.keys(timers[containerId] || {}).length) {
        delete timers[containerId];
      }
    }
  }
};

export const addContainerTimer = (containerId: string, timerName: string, timerId: ReturnType<typeof setTimeout>) => {
  removeContainerTimer(containerId, timerName);
  const { containers: containerTimes } = globalThis.Baxter.state.user.timers;
  if (!containerTimes[containerId]) {
    Objects.set(containerTimes, containerId, {});
  }
  Objects.set(containerTimes[containerId], timerName, timerId);
};

export const removeContainerTimers = (entityId: string, timerName?: string) => {
  const timers = globalThis.Baxter.state.user.timers.containers;
  if (timers && timers[entityId]) {
    for (const keyState of Object.keys(timers[entityId])) {
      if (!timerName || timerName === keyState) {
        removeContainerTimer(entityId, keyState);
      }
    }
  }
};

export const removeGeneralTimer = (timerName: string) => {
  globalThis.Baxter.state.user.timers.generals = globalThis.Baxter.state.user.timers.generals.filter(
    ({ name, timer }) => {
      const isTimerToClear = name === timerName;
      if (isTimerToClear) clearTimeout(timer);
      return !isTimerToClear;
    }
  );
};

export const addGeneralTimer = (name: string, timer: ReturnType<typeof setTimeout>) => {
  globalThis.Baxter.state.user.timers.generals.push({ name, timer });
};

export const getUser = () => globalThis.Baxter.state.user;

export const getBreakpoints = () => globalThis.Baxter.state.app.breakpoints;

export const getBreakpoint = () => globalThis.Baxter.state.app.breakpoint;

export const setDeviceSize = () => {
  const breakpoint = getBreakpoint();
  const { breakpoints } = globalThis.Baxter.config.app;
  globalThis.Baxter.state.user.deviceSize = Object.keys(breakpoints).find(
    (device) => breakpoints[device] === breakpoint
  );
};

export const setDeviceType = () => {
  const ua = globalThis.navigator.userAgent;
  let deviceType = DesktopTypes.DESKTOP;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    deviceType = DesktopTypes.TABLET;
  } else if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    deviceType = DesktopTypes.MOBILE;
  }
  globalThis.Baxter.state.user.deviceType = deviceType;
};

export const setPreviewToken = () => {
  globalThis.Baxter.state.user.previewToken = Preview.getCookie();
};

export const getPreviewToken = () => globalThis.Baxter.state.user.previewToken;

export const setCxenseSegments = (segments: unknown[]) => {
  globalThis.Baxter.state.user.cxense.segments = segments;
};

export const getCxenseSegments = () => globalThis.Baxter.state.user.cxense.segments;

export const setCxensePageViews = (count = 0) => {
  globalThis.Baxter.state.user.cxense.pageViews = count;
};

export const getCxensePageViews = () => globalThis.Baxter.state.user.cxense.pageViews || 0;

export const setCxenseIntervalId = (id: NodeJS.Timeout) => {
  globalThis.Baxter.state.user.cxense.intervalId = id;
};

export const getCxenseIntervalId = () => globalThis.Baxter.state.user.cxense.intervalId;

export const getSatiCallQueueInit = () => globalThis.Baxter.state.user.sati.init;

export const setSatiCallQueueInit = (init: boolean) => {
  globalThis.Baxter.state.user.sati.init = init;
};

export const setBreakpoint = () => {
  const { breakpoints } = globalThis.Baxter.state.app;
  globalThis.Baxter.state.app.breakpoint = breakpoints?.find((val) => val <= globalThis.innerWidth);
  setDeviceSize();
};

export const setBreakpoints = () => {
  const settings = globalThis.Baxter.config.app?.breakpoints || {};
  const breakpoints: number[] = [];
  Object.values(settings).forEach((breakpoint) => {
    const numericBreakpoint = Number(breakpoint);
    if (!Number.isNaN(numericBreakpoint)) {
      breakpoints.push(numericBreakpoint);
    }
  });
  breakpoints.sort((a, b) => b - a);
  console.debug('[SLOTS][STATE][SETBREAKPOINTS]', breakpoints);
  globalThis.Baxter.state.app.breakpoints = breakpoints;
  setBreakpoint();
};

export const setContainerSet = (containerId: string) => {
  const pageId = getPageId();
  if (!pageId) return;
  if (!globalThis.Baxter.state.app.set[pageId]) globalThis.Baxter.state.app.set[pageId] = {};
  globalThis.Baxter.state.app.set[pageId][containerId] = Date.now();
};

export const getContainerSet = (containerId: string) => {
  const pageId = getPageId();
  if (!pageId) return {};
  return globalThis.Baxter.state.app.set[pageId]?.[containerId];
};

export const clearContainerSet = (containerId: string) => {
  const pageId = getPageId();
  if (!pageId) return;
  const pageSettings = globalThis.Baxter.state.app.set?.[pageId] || {};
  if (pageSettings[containerId]) delete pageSettings[containerId];
};

export const setPageTrackers = (callback: () => void) => {
  if (typeof callback === 'function') globalThis.Baxter.state.trackers.push(callback);
};

export const getPageTrackers = () => globalThis.Baxter.state.trackers;

export const setDependencyResolved = (url: string, success: boolean) => {
  console.info('[SLOTS][GOOGLEIMA][SETDEPENDENCYRESOLVED]', url, success);
  globalThis.Baxter.state.resolvedDependencies = globalThis.Baxter.state.resolvedDependencies || [];
  globalThis.Baxter.state.resolvedDependencies.push({ url, success });
};

export const areDependenciesResolved = (urls: string[]) =>
  urls.every((urlToFind) => globalThis.Baxter.state.resolvedDependencies?.find(({ url }) => urlToFind === url));

export const areDependenciesResolvedWithSuccess = (urls: string[]) =>
  urls.every((urlToFind) =>
    globalThis.Baxter.state.resolvedDependencies?.find(({ url, success }) => urlToFind === url && success)
  );

export const bootstrap = () => {
  setBreakpoints();
  setDeviceSize();
  setDeviceType();
  setProviders();
  setPreviewToken();
};
