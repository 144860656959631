import { Config } from 'Types/Config';
import * as Provider from 'Domain/version/web/core/Provider';
import * as Html from 'Helper/browser/Html';
import { Providers } from 'Domain/version/web/config/Providers';
import { GoogleImaConfig, GoogleImaCoreConfig } from 'Types/ProviderSettings/GoogleIma';
import { Features } from 'Domain/version/web/config/Features';
import * as State from 'Domain/version/web/core/State';
import { Observers } from 'Domain/version/web/config/Observers';
import { AutoplaySlot } from 'Types/Slot';

export const id = Features.AUTOPLAY;
const GOOGLE_IMA_ID = Providers.GOOGLE_IMA;

export const webpackExclude = (config: Config) => {
  const providerSettings = (config.slots?.providerSettings?.[GOOGLE_IMA_ID] || {}) as GoogleImaConfig;
  const valuesDefault = providerSettings.core?._ || {};
  const values = providerSettings.core || {};
  return !(
    Object.values(valuesDefault).some((item) => item?.autoplay) || Object.values(values).some((item) => item?.autoplay)
  );
};

const isEnabledForSlot = (pageId: string, containerId: string, slotId: string): boolean => {
  const providerSettings = (globalThis.Baxter.config.slots?.providerSettings?.[GOOGLE_IMA_ID] || {}) as GoogleImaConfig;
  const core = (globalThis.Baxter.context.configurationService.getById(
    providerSettings.core,
    pageId,
    containerId,
    slotId
  ) || {}) as GoogleImaCoreConfig;
  return core.autoplay;
};

const transform = (pageId: string, containerId: string, slotId: string): AutoplaySlot => {
  const providerSettings = (globalThis.Baxter.config.slots?.providerSettings?.[GOOGLE_IMA_ID] || {}) as GoogleImaConfig;
  const core = (globalThis.Baxter.context.configurationService.getById(
    providerSettings.core,
    pageId,
    containerId,
    slotId
  ) || {}) as GoogleImaCoreConfig;
  return {
    [id]: {
      config: core,
    },
  } as AutoplaySlot;
};

const onIntersection = (slot: AutoplaySlot, element: IntersectionObserverEntry) => {
  const htmlElement = Html.getElementById(slot.innerId);
  if (htmlElement && htmlElement.getBoundingClientRect().height > 0 && element.intersectionRatio > 0.5) {
    Provider.autoplay(slot);
  }
};

const onResize = (slot: AutoplaySlot) => {
  const htmlElement = Html.getElementById(slot.innerId);
  if (!htmlElement) {
    return;
  }
  const boundingRect = htmlElement.getBoundingClientRect();
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const top = Math.max(boundingRect.top, 0);
  const bottom = Math.min(boundingRect.bottom, viewportHeight);
  if (boundingRect.height > 0 && bottom - top > boundingRect.height / 2) {
    Provider.autoplay(slot);
  }
};

const addContainerObservers = (containerId: string) => {
  State.addElementObserver(containerId, Observers.intersectionAutoplay, {
    threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
  });
  State.addElementObserver(containerId, Observers.resizeAutoplay);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const remove = (slot: AutoplaySlot) => {
  State.removeElementObserver(Observers.intersectionAutoplay);
  State.removeElementObserver(Observers.resizeAutoplay);
};

export default {
  id,
  transform,
  isEnabledForSlot,
  onIntersection,
  onResize,
  addContainerObservers,
  remove,
};
