import ninjaMetrics from 'Helper/metrics/NinjaMetrics';
import * as Html from 'Helper/browser/Html';
import Placeholder from 'Domain/version/web/feature/Placeholder';
import * as Interstitial from 'Domain/version/web/provider/googleads/Interstitial';
import { buildSlotRenderedEvent } from 'Domain/version/web/core/SlotRenderedEventListener';
import { id } from 'Domain/version/web/provider/googleads/GoogleAds';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';

export const impressionCallback = (external, containerId, slotId, pageId, path, slot) => (event) => {
  try {
    console.info('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK]', event);
    if (event.slot === external) {
      slot.impression = true;
      slot.impressionScroll = window.scrollY;
      ninjaMetrics.reportMetric('advertisement_viewed', { ad_unit_id: path });
      newRelicMetrics.reportMetric(BaxterMetric.AD_IMPRESSION, { containerId, slotId, pageId, path });
      if (slot.interstitial?.enabled) {
        ninjaMetrics.reportMetric('interstitial_viewable', { ad_unit_id: path });
        console.debug('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK] interstitial_viewable event', path);
      }
    }
  } catch (e) {
    console.error('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK]', e);
    newRelicMetrics.reportError(BaxterError.GOOGLEADS_IMPRESSION_CALLBACK_ERROR, { message: (e as Error).message });
  }
};

export const setCampaignId = (slot, external) => {
  if (!slot.params.campaignId) slot.params.campaignId = 0;
  if (external.getResponseInformation) {
    const slotData = external.getResponseInformation() || {};
    slot.params.campaignId = slotData.campaignId || 0;
  }
};

export const renderedCallback =
  (external, slot, innerId, containerId, pageId, interstitial, slotId, status, path) => (event) => {
    try {
      console.info('[SLOTS][GOOGLEADS][RENDEREDCALLBACK]', event);
      if (external === event.slot) {
        setCampaignId(slot, external);
        const inner = Html.getElementById(innerId);
        if (inner && inner.style?.display !== 'none') {
          Placeholder.remove(containerId);
        }
        if (inner && interstitial.enabled && !event.isEmpty) {
          const { abTest } = slot.params;
          const { INTERSTITIAL_AD_WITH_PRELOADING } = Interstitial.interstitialAbTests;
          if (abTest.includes(INTERSTITIAL_AD_WITH_PRELOADING)) {
            Interstitial?.storeInState?.(slot);
            ninjaMetrics.reportMetric('interstitial_impression', { ad_unit_id: path });
            console.debug('[SLOTS][GOOGLEADS][RENDEREDCALLBACK] interstitial_impression event', path);
          }
        }
        if (!event.isEmpty) {
          ninjaMetrics.reportMetric('advertisement_displayed', { ad_unit_id: slot.path });
          slot.impression = false;
          slot.impressionScroll = null;
        }
        window.dispatchEvent(
          buildSlotRenderedEvent(pageId, containerId, slotId, status, event.slot, event.isEmpty, id)
        );
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][RENDEREDCALLBACK]', e);
      newRelicMetrics.reportError(BaxterError.GOOGLEADS_RENDERED_CALLBACK_ERROR, { message: (e as Error).message });
    }
  };

export const requestedCallback = (interstitial, path) => (event) => {
  try {
    console.info('[SLOTS][GOOGLEADS][REQUESTEDCALLBACK]', event);
    if (interstitial.enabled) {
      ninjaMetrics.reportMetric('interstitial_adrequest_sent', { ad_unit_id: path });
      console.debug('[SLOTS][GOOGLEADS][REQUESTEDCALLBACK] interstitial_adrequest_sent event', path);
    }
  } catch (e) {
    console.error('[SLOTS][GOOGLEADS][REQUESTEDCALLBACK]', e);
    newRelicMetrics.reportError(BaxterError.GOOGLEADS_REQUESTED_CALLBACK_ERROR, { message: (e as Error).message });
  }
};

export const responseReceivedCallback = (interstitial, path) => (event) => {
  try {
    console.info('[SLOTS][GOOGLEADS][RESPONSERECEIVEDCALLBACK] ', event);
    if (interstitial.enabled) {
      ninjaMetrics.reportMetric('interstitial_adrequest_received', { ad_unit_id: path });
      console.debug('[SLOTS][GOOGLEADS][RESPONSERECEIVEDCALLBACK] interstitial_adrequest_received event', path);
    }
  } catch (e) {
    console.error('[SLOTS][GOOGLEADS][RESPONSERECEIVEDCALLBACK]', e);
    newRelicMetrics.reportError(BaxterError.GOOGLEADS_RESPONSE_RECEIVED_CALLBACK_ERROR, {
      message: (e as Error).message,
    });
  }
};
