import Objects from 'Helper/object/Object';
import * as Prebid from 'Domain/version/web/provider/googleads/bidders/GoogleAdsPrebid';
import * as Aps from 'Domain/version/web/provider/googleads/bidders/GoogleAdsAps';
import * as State from 'Domain/version/web/core/State';
import * as Html from 'Helper/browser/Html';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';
import { Providers } from 'Domain/version/web/config/Providers';
import { Slot } from 'Types/Slot';
import { GoogleAdsConfig, GoogleAdsApsConfig, GoogleAdsPrebidConfig } from 'Types/ProviderSettings/GoogleAds';
import { Config } from 'Types/Config';
import { Bidders } from 'Domain/version/web/config/Bidders';
import { Bidder, SlotsByType } from 'Types/Bidders';

const FAILSAFE_TIMEOUT = 1100;
const FAILSAFE_TIMEOUT_DELTA = 500;
export const id = Providers.GOOGLE_ADS;
const BIDDER_REQUEST_TIMER_KEY = 'bidder-request-timer';

const allBidders: Bidder[] = [
  { id: Bidders.PREBID, module: Prebid },
  { id: Bidders.APS, module: Aps },
];

const sourceToMetricName = () => ({
  prebid: BaxterMetric.BIDDERS_PREBID_SENT_AD_SERVER_REQUEST,
  aps: BaxterMetric.BIDDERS_APS_SENT_AD_SERVER_REQUEST,
  timeout: BaxterMetric.BIDDERS_TIMEOUT_SENT_AD_SERVER_REQUEST,
});

const enabledSomeBidderForSomeSlot =
  (providerSettings: GoogleAdsConfig | undefined) =>
  (bidder: Bidder): boolean => {
    const valuesDefault = providerSettings?.[bidder.id]?._ || {};
    const values = (providerSettings?.[bidder.id] || {}) as { [k: string]: GoogleAdsApsConfig | GoogleAdsPrebidConfig };
    return !!(
      Object.values(valuesDefault).find((item) => Objects.isObject(item) && item?.enabled === true) ||
      Object.values(values).find((item) => Objects.isObject(item) && item?.enabled === true)
    );
  };

export const webpackExclude = (config: Config): boolean => {
  const providerSettings = config.slots?.providerSettings?.[id] as GoogleAdsConfig | undefined;
  return !allBidders.some(enabledSomeBidderForSomeSlot(providerSettings));
};

const enabledBidders = (): Bidder[] => {
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] as GoogleAdsConfig | undefined;
  return allBidders.filter(enabledSomeBidderForSomeSlot(providerSettings));
};

const enabledBidderForSlot =
  (providerSettings: GoogleAdsConfig | undefined, containerId: string, slotId: string) =>
  (bidder: Bidder): boolean => {
    const bidderSettings = providerSettings?.[bidder.id];
    const bidderSlot = globalThis.Baxter.context.configurationService.getById(
      bidderSettings,
      State.getPageId(),
      containerId,
      slotId
    );
    return !!bidderSlot?.enabled;
  };

const enabledBiddersForSlot = (containerId: string, slotId: string): Bidder[] => {
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] as GoogleAdsConfig | undefined;
  return allBidders.filter(enabledBidderForSlot(providerSettings, containerId, slotId));
};

export const enabledSomeBidderForSlot = (containerId: string, slotId: string): boolean => {
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] as GoogleAdsConfig | undefined;
  return allBidders.some(enabledBidderForSlot(providerSettings, containerId, slotId));
};

export const getSlotsByType = (slots: Slot[]): SlotsByType => {
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] as GoogleAdsConfig | undefined;
  const byBidder: Record<string, Slot[]> = {};
  const bidders: Slot[] = [];
  const google: Slot[] = [];
  for (const slot of slots) {
    let slotWithBidderEnabled = false;
    allBidders.forEach((bidder) => {
      if (enabledBidderForSlot(providerSettings, slot.containerId, slot.id)(bidder)) {
        if (!byBidder[bidder.id]) {
          byBidder[bidder.id] = [];
        }
        byBidder[bidder.id].push(slot);
        if (!slotWithBidderEnabled) {
          bidders.push(slot);
        }
        slotWithBidderEnabled = true;
      }
    });
    if (!slotWithBidderEnabled) {
      google.push(slot);
    }
  }
  return {
    byBidder,
    bidders,
    google,
  };
};

export const init = () => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][INIT]');
  enabledBidders().forEach((bidder) => bidder.module.init());
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][DEPENDENCIES]');
  return enabledBidders().flatMap((bidder) => bidder.module.dependencies());
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][LOADED]');
  enabledBidders().forEach((bidder) => bidder.module.loaded());
};

export const setTargeting = (targeting) => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][SETTARGETING]');
  enabledBidders().forEach((bidder) => bidder.module.setTargeting(targeting));
};

export const transform = async (pageId, containerId, slotId, params) => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][TRANSFORM]');
  let result = {};
  for (const bidder of enabledBiddersForSlot(containerId, slotId)) {
    // eslint-disable-next-line no-await-in-loop
    const bidderTransformSlot = await bidder.module.transform(pageId, containerId, slotId, params);
    result = {
      ...result,
      ...bidderTransformSlot,
    };
  }
  return result;
};

export const create = async (slot: Slot, providerFn) => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][CREATE]', slot);
  const slotBidders = enabledBiddersForSlot(slot.containerId, slot.id);
  const createdSlotBidders = {};
  const callback = (bidderId) => () => {
    console.info('[SLOTS][GOOGLEADSBIDDERS][CREATE][CALLBACK] bidder created', bidderId);
    createdSlotBidders[bidderId] = true;
    if (slotBidders.every((bidder) => createdSlotBidders[bidder.id])) {
      providerFn(slot);
    }
  };
  slotBidders.forEach((bidder) => bidder.module.create(slot, callback(bidder.id)));
};

export const load = (slotsByType: SlotsByType) => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][LOAD]', slotsByType);
  const loadedSlotsBidders = {};
  let adServerRequestSent = false;

  const sendAdServerRequest = (source) => {
    if (adServerRequestSent) {
      console.debug(
        `[SLOTS][GOOGLEADSBIDDERS][LOAD][SENDADSERVERREQUEST] ${source} not sending as request already sent`
      );
      return;
    }
    adServerRequestSent = true;
    console.debug(`[SLOTS][GOOGLEADSBIDDERS][LOAD][SENDADSERVERREQUEST] ${source} sending request`);
    if (sourceToMetricName()[source]) {
      newRelicMetrics.reportMetric(sourceToMetricName()[source], {
        command: '[GOOGLEADSBIDDERSLOAD]',
      });
    }
    globalThis.googletag.cmd.push(() => {
      try {
        const slots = slotsByType.google
          .concat(slotsByType.bidders)
          .filter((slot) => slot.external && Html.getElementById(slot.innerId));
        console.debug(
          `[SLOTS][GOOGLEADSBIDDERS][LOAD][SENDADSERVERREQUEST] ${source} googletag.pubads().refresh(...)`,
          slots
        );
        const externals = slots.map((slot) => slot.external);
        globalThis.googletag.pubads().refresh(externals);
      } catch (e) {
        console.error('[SLOTS][GOOGLEADSBIDDERS][LOAD]', e);
        newRelicMetrics.reportError(BaxterError.GOOGLEADS_COMMAND_ERROR, {
          command: '[GOOGLEADSBIDDERSLOAD]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  };
  const callback = (bidderId) => () => {
    console.info('[SLOTS][GOOGLEADSBIDDERS][LOAD][CALLBACK] bidder loaded', bidderId);
    loadedSlotsBidders[bidderId] = true;
    if (Object.keys(slotsByType.byBidder).every((slotsByTypeBidderId) => loadedSlotsBidders[slotsByTypeBidderId])) {
      sendAdServerRequest(bidderId);
    } else {
      console.debug(`[SLOTS][GOOGLEADSBIDDERS][LOAD][CALLBACK] ${bidderId} not sending as not all bidders in place`);
    }
  };
  let failSafeTimeout = FAILSAFE_TIMEOUT;
  Object.entries(slotsByType.byBidder).forEach(([bidderId, bidderSlots]) => {
    const providerConfig = globalThis.Baxter.config.providers[id];
    const bidderSettings = providerConfig[bidderId]?.settings || {};
    if (bidderSettings.timeout && bidderSettings.timeout + FAILSAFE_TIMEOUT_DELTA > failSafeTimeout) {
      failSafeTimeout = bidderSettings.timeout + FAILSAFE_TIMEOUT_DELTA;
    }
    allBidders.find((bidder) => bidder.id === bidderId)?.module?.load(bidderSlots, callback(bidderId));
  });
  console.debug('[SLOTS][GOOGLEADSBIDDERS][LOAD] failsafetimeout', failSafeTimeout);
  const timer = setTimeout(() => {
    try {
      console.info('[SLOTS][GOOGLEADSBIDDERS][LOAD][TIMEOUT]');
      sendAdServerRequest('timeout');
    } catch (e) {
      console.error('[SLOTS][GOOGLEADSBIDDERS][LOAD]', e);
      newRelicMetrics.reportError(BaxterError.BIDDERS_TIMEOUT_ERROR, {
        command: '[GOOGLEADSBIDDERSLOAD]',
        message: (e as Error).message,
      });
    }
  }, failSafeTimeout);
  State.addGeneralTimer(BIDDER_REQUEST_TIMER_KEY, timer);
};

export const refresh = (slots: Slot[] = []) => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][REFRESH]', slots);
  slots.forEach((slot) => {
    if (slot.external) {
      const slotBidders = enabledBiddersForSlot(slot.containerId, slot.id);
      const refreshedSlotBidders = {};
      let adServerRequestSent = false;

      const sendAdServerRequest = (source) => {
        if (adServerRequestSent) {
          console.debug(`[SLOTS][GOOGLEADSBIDDERS][REFRESH][SENDADSERVERREQUEST] ${slot.id} ${source} already sent`);
          return;
        }
        adServerRequestSent = true;
        console.debug(`[SLOTS][GOOGLEADSBIDDERS][REFRESH][SENDADSERVERREQUEST] ${slot.id} ${source} sending request`);
        if (sourceToMetricName()[source]) {
          newRelicMetrics.reportMetric(sourceToMetricName()[source], {
            command: '[GOOGLEADSBIDDERSREFRESH]',
          });
        }
        globalThis.googletag.cmd.push(() => {
          try {
            const changeCorrelator = !(slot.status.lazyLoad && !slot.filled);
            console.debug(
              `[SLOTS][GOOGLEADSBIDDERS][REFRESH][SENDADSERVERREQUEST] ${slot.id} ${source} googletag.pubads().refresh(...)`,
              slot
            );
            globalThis.googletag.pubads().refresh([slot.external], { changeCorrelator });
          } catch (e) {
            console.error('[SLOTS][GOOGLEADSBIDDERS][REFRESH]', e);
            newRelicMetrics.reportError(BaxterError.GOOGLEADS_COMMAND_ERROR, {
              command: '[GOOGLEADSBIDDERSREFRESH]',
              message: (e as Error).message,
            });
            throw e;
          }
        });
      };
      const callback = (bidderId) => () => {
        console.info(`[SLOTS][GOOGLEADSBIDDERS][REFRESH][CALLBACK] ${slot.id} bidder loaded`, bidderId);
        refreshedSlotBidders[bidderId] = true;
        if (slotBidders.every((slotBidder) => refreshedSlotBidders[slotBidder.id])) {
          sendAdServerRequest(bidderId);
        } else {
          console.debug(
            `[SLOTS][GOOGLEADSBIDDERS][REFRESH][CALLBACK] ${bidderId} not sending as not all bidders in place`
          );
        }
      };
      let failSafeTimeout = FAILSAFE_TIMEOUT;
      slotBidders.forEach((bidder) => {
        const providerConfig = globalThis.Baxter.config.providers[id];
        const bidderSettings = (providerConfig?.[bidder.id]?.settings || {}) as { timeout };
        if (bidderSettings.timeout && bidderSettings.timeout + FAILSAFE_TIMEOUT_DELTA > failSafeTimeout) {
          failSafeTimeout = bidderSettings.timeout + FAILSAFE_TIMEOUT_DELTA;
        }
        bidder.module.refresh(slot, callback(bidder.id));
      });
      console.debug('[SLOTS][GOOGLEADSBIDDERS][REFRESH] failsafetimeout', failSafeTimeout);
      const timer = setTimeout(() => {
        try {
          console.info('[SLOTS][GOOGLEADSBIDDERS][REFRESH][TIMEOUT]');
          sendAdServerRequest('timeout');
        } catch (e) {
          console.error('[SLOTS][GOOGLEADSBIDDERS][REFRESH]', e);
          newRelicMetrics.reportError(BaxterError.BIDDERS_TIMEOUT_ERROR, {
            command: '[GOOGLEADSBIDDERSREFRESH]',
            message: (e as Error).message,
          });
        }
      }, failSafeTimeout);
      State.addContainerTimer(slot.containerId, BIDDER_REQUEST_TIMER_KEY, timer);
    }
  });
};

export const remove = (slots: Slot[] = []) => {
  console.info('[SLOTS][GOOGLEADSBIDDERS][REMOVE]', slots);
  slots.forEach((slot) => {
    State.removeContainerTimers(slot.containerId, BIDDER_REQUEST_TIMER_KEY);
  });
  enabledBidders().forEach((bidder) => bidder.module.remove(slots));
};

export const clear = () => {
  State.removeGeneralTimer(BIDDER_REQUEST_TIMER_KEY);
};

export default {
  enabledSomeBidderForSlot,
  getSlotsByType,
  init,
  dependencies,
  loaded,
  transform,
  create,
  load,
  refresh,
  remove,
  setTargeting,
  clear,
};
