import * as ObjectTransform from 'Helper/object/ObjectTransform';

export const clone = (object) => JSON.parse(JSON.stringify(object || {}));

export const isObject = (obj, allowArray = false) =>
  obj && typeof obj === 'object' && (!Array.isArray(obj) || allowArray);

export const hasDiff = (obj1, obj2) => {
  let diff = false;
  // todo: standardise objects first
  obj1 = obj1 || {};
  obj2 = obj2 || {};
  Object.keys(obj1).forEach((name) => {
    const var1 = obj1[name];
    const var2 = obj2[name];
    const match = var1 && var2 && JSON.stringify(var1) === JSON.stringify(var2);
    if (!match) diff = true;
  });
  return diff;
};

export const merge = (...objects) => {
  const merged = objects.filter(Boolean).reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = oVal.map((oValItem, index) => {
          const pValItem = pVal[index];
          if (isObject(pValItem) && isObject(oValItem)) {
            return merge(pValItem, oValItem);
          }
          return pValItem && oValItem === null ? pValItem : oValItem;
        });
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = merge(pVal, oVal);
      } else {
        prev[key] = pVal && oVal === null ? pVal : oVal;
      }
    });

    return prev;
  }, {});

  // eslint-disable-next-line no-restricted-globals
  return !Object.keys(merged).find((key) => isNaN(Number(key)))
    ? Object.entries(merged).map((item) => item[1])
    : merged;
};

function getArrayIndex(currentNode) {
  const keySplit = currentNode.split('[');
  let arrayIndex = -1;
  if (keySplit.length > 1) {
    for (let i = 1; i < keySplit.length; i++) {
      arrayIndex = parseInt(keySplit[i].split(']')[0], 10);
    }
  }
  return arrayIndex;
}

export const get = (obj, path) => {
  if (path && typeof path === 'string' && obj != null) {
    const nodes = path.split('.');
    const currentNode = nodes[0].split('[')[0];
    const arrayIndex = getArrayIndex(nodes[0]);

    if (
      !currentNode ||
      !obj.hasOwnProperty(currentNode) ||
      (arrayIndex > 0 && !obj[currentNode].hasOwnProperty(arrayIndex))
    ) {
      return undefined;
    }
    const currentElement = arrayIndex > 0 ? obj[currentNode][arrayIndex] : obj[currentNode];
    if (nodes.length === 1) {
      return currentElement;
    }
    nodes.shift();
    return get(currentElement, nodes.join('.'));
  }
};

function isArrayIndex(path, i) {
  // eslint-disable-next-line no-restricted-globals
  return isNaN(path[i + 1]);
}

function objectAlreadyContainsProperty(valueObj, currentPath) {
  return typeof valueObj[currentPath] === 'object' && valueObj[currentPath] !== null;
}

export const set = (obj, pathString, value) => {
  if (obj === null || typeof obj !== 'object' || typeof pathString !== 'string') {
    return obj;
  }
  const path = pathString.toString().match(/[^.[\]]+/g) || [];
  const pathWithoutLast = path.slice(0, -1);
  const lastInPath = pathWithoutLast.reduce(
    // eslint-disable-next-line no-return-assign
    (valueObj, currentPath, i) =>
      objectAlreadyContainsProperty(valueObj, currentPath)
        ? valueObj[currentPath]
        : (valueObj[currentPath] = isArrayIndex(path, i) ? [] : {}),
    obj
  );
  lastInPath[path[path.length - 1]] = value;
  return obj;
};

export const parseMap = (objectMap: Record<string, string | number>[] = [], variableMap = {}, ranges = {}) =>
  ObjectTransform.mapFromVariables(objectMap, variableMap, ranges);

export default {
  get,
  set,
  clone,
  merge,
  isObject,
  hasDiff,
  parseMap,
};
