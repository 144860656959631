import * as State from 'Domain/version/web/core/State';
import * as Condition from 'Helper/query/Condition';
import * as Objects from 'Helper/object/Object';
import {
  SlotContainer,
  SlotContainerRepository,
} from 'Domain/version/web/core/repositories/inmemoroy/SlotContainerRepository';
import { ConfigurationService } from 'Domain/version/web/configuration/ConfigurationService';
import { ContainerParamsRepository } from 'Domain/version/web/core/repositories/inmemoroy/ContainerParamsRepository';
import { FillContainerMetrics } from 'Domain/version/web/core/services/FillContainerMetrics';
import { TargetingParams } from 'Types/TargetingParams';

/**
 * @deprecated
 * Extend Slot, Lifecycle, etc. entities instead or create a new one.
 * Use globalThis.Baxter.state as storage.
 */
export class SlotForContainerFinder {
  constructor(
    private configService: ConfigurationService,
    private containersParamsRepository: ContainerParamsRepository,
    private slotContainerRepository: SlotContainerRepository,
    private fillContainerMetrics: FillContainerMetrics
  ) {
    this.configService.getPageIds().forEach((pageId) => {
      this.slotContainerRepository.saveAll(
        configService
          .getContainerByPageId(pageId)
          .map((container) => new SlotContainer(pageId, container.id, container.slots))
      );
    });
  }

  find = (containerId, params: TargetingParams = {}) => {
    const pageId = State.getPageId();
    const slots = this.getSlotsForContainer(pageId, containerId);
    this.containersParamsRepository.save(pageId!, containerId, params);
    const slot = slots.find((slotId) =>
      Condition.query(
        this.configService.getRuleCondition(pageId, containerId, slotId),
        this.getQueryParams(containerId, params)
      )
    );
    if (!slot) {
      this.fillContainerMetrics.registerNoMoreSlots(pageId, containerId);
    }
    params.slot = slot;
    return params.slot as string;
  };

  setEmptySlot(pageId, containerId, slotId) {
    this.slotContainerRepository.find(pageId, containerId).addNoFill(slotId);
  }

  getSlotsForContainer(pageId, containerId) {
    const slotsForContainer = this.configService.getSlotsForContainer(pageId, containerId);
    const containerSlot = this.slotContainerRepository.find(pageId, containerId);
    return slotsForContainer.filter((slot) => containerSlot.didNotFailToFill(slot));
  }

  getQueryParams = (containerId, params = {}) => {
    const slot = State.getSlot(containerId) || {
      status: {
        loaded: false,
        visible: false,
        fold: null,
        lazyLoad: false,
        refreshCount: 0,
        refreshPending: false,
      },
    };
    return Objects.clone({
      ...State.getUser(),
      ...(slot.status || {}),
      ...(slot.params || {}),
      ...(params || {}),
    });
  };
}
