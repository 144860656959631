import * as Objects from 'Helper/object/Object';
import * as Html from 'Helper/browser/Html';
import * as State from 'Domain/version/web/core/State';
import * as Provider from 'Domain/version/web/core/Provider';
import * as Slot from 'Domain/version/web/core/Slot';
import Gemius from 'Domain/version/web/feature/Gemius';
import LazyLoad from 'Domain/version/web/feature/LazyLoad';
import Consent from 'Domain/version/web/feature/Consent';
import Cxense from 'Domain/version/web/feature/Cxense';
import BreakpointRefresh from 'Domain/version/web/feature/BreakpointRefresh';
import Placeholder from 'Domain/version/web/feature/Placeholder';
import Sticky from 'Domain/version/web/feature/Sticky';
import * as Interstitial from 'Domain/version/web/provider/googleads/Interstitial';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { Container } from 'Types/Container';
import { ContainerParams, TargetingParams } from 'Types/TargetingParams';
import { Observers } from 'Domain/version/web/config/Observers';
import Autoplay from 'Domain/version/web/feature/Autoplay';
import { Features } from 'Domain/version/web/config/Features';
import { AutoplaySlot, StickySlot } from 'Types/Slot';

const UNLOADED_SLOTS_TIMER_KEY = 'unloaded-slots-timer';

const setContainerSlot = async (paramsByContainerId: ContainerParams, containers: Container[]) => {
  await Promise.all(
    containers.map(async (container) => {
      if (!['refresh'].includes(container.type)) {
        const slotParams = paramsByContainerId[container.id] || State.getPageParams();
        await Slot.set(container.id, Objects.clone(slotParams), false);
      }
    })
  );
};

export const onLoad = async (retryCount = 25) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONLOAD]', retryCount);
    if (Interstitial?.isVisible?.()) {
      console.debug(`[SLOTS][LIFECYCLE][ONLOAD] State.setInterstitialAction`);
      State.setInterstitialAction(async () => onLoad(retryCount));
      return;
    }
    // block load if no consent
    if (Consent && Consent.delayLoad()) {
      return;
    }
    // load unloaded slots
    const slotsToLoad = Slot.getLoadable();
    console.debug('[SLOTS][LIFECYCLE][ONLOAD] Provider.load', slotsToLoad);
    await Provider.load(slotsToLoad);

    // update slot loaded status
    Object.keys(slotsToLoad).forEach((containerId) => {
      slotsToLoad[containerId].status.loaded = true;
    });

    // reload if there are remaining unloaded slots
    const remainingSlots = Slot.getUnloaded();
    console.debug(`[SLOTS][LIFECYCLE][ONLOAD] remainingSlots`, remainingSlots);
    if (retryCount > 0 && remainingSlots.length > 0) {
      const timer = setTimeout(async () => {
        console.debug(`[SLOTS][LIFECYCLE][ONLOAD] onLoad`, retryCount - 1);
        await onLoad(retryCount - 1);
      }, 100);
      State.addGeneralTimer(UNLOADED_SLOTS_TIMER_KEY, timer);
    } else if (retryCount === 0 && remainingSlots.length > 0) {
      newRelicMetrics.reportError(BaxterError.LIFECYCLE_SLOTS_NOT_LOADED, {});
    }
  } catch (error) {
    console.error('[SLOTS][LIFECYCLE][ONLOAD]', error);
    newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_LOAD_ERROR, { message: (error as Error).message });
  }
};

export const onSetAfterLoaded = async (paramsByContainerId: ContainerParams = {}, autoload = true) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONSETAFTERLOADED]', paramsByContainerId, autoload);
    if (Consent && Consent.isPending()) {
      console.debug('[SLOTS][LIFECYCLE][ONSETAFTERLOADED] CONSENT DELAY');
      Consent.addToQueue('set', async () => {
        await onSetAfterLoaded(paramsByContainerId, autoload);
      });
      return;
    }

    const pageId = State.getPageId() || '';
    const containers = globalThis.Baxter.config.containers || {};
    const pageContainers = containers[pageId] || [];
    const deferredContainers = Object.keys(paramsByContainerId).length
      ? pageContainers.filter(({ id }) => id in paramsByContainerId)
      : pageContainers.filter(({ id }) => !globalThis.Baxter.state?.slots?.[id]);

    if (deferredContainers.length) {
      console.debug('[SLOTS][LIFECYCLE][ONSETAFTERLOADED] setContainerSlot', paramsByContainerId, deferredContainers);
      await setContainerSlot(paramsByContainerId, deferredContainers);
      if (autoload) {
        console.debug('[SLOTS][LIFECYCLE][ONSETAFTERLOADED] onload');
        await onLoad();
      }
    } else {
      console.error('[SLOTS][LIFECYCLE][ONSETAFTERLOADED] NO DEFERRED CONTAINERS FOUND FOR PAGE');
      newRelicMetrics.reportError(BaxterError.LIFECYCLE_NO_DEFFERED_CONTAINERS_FOUND, { pageId });
    }
  } catch (error) {
    console.error('[SLOTS][LIFECYCLE][ONSETAFTERLOADED]', error);
    newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_SET_AFTER_LOADED_ERROR, { message: (error as Error).message });
  }
};

export const onSet = async (paramsByContainerId: ContainerParams = {}, autoload = true) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONSET]', paramsByContainerId, autoload);
    if (Interstitial?.isVisible?.()) {
      console.debug('[SLOTS][LIFECYCLE][ONSET] State.setInterstitialAction');
      State.setInterstitialAction(async () => onSet(paramsByContainerId, autoload));
      return;
    }
    if (Consent && Consent.isPending()) {
      console.debug('[SLOTS][LIFECYCLE][ONSET] CONSENT DELAY');
      Consent.addToQueue('set', async () => {
        await onSet(paramsByContainerId, autoload);
      });
      return;
    }

    const pageId = State.getPageId() || '';
    const containers = globalThis.Baxter.config.containers || {};
    const pageContainers = containers[pageId] || [];

    if (pageContainers.length) {
      console.debug('[SLOTS][LIFECYCLE][ONSET] setContainerSlot', paramsByContainerId, pageContainers);
      await setContainerSlot(paramsByContainerId, pageContainers);
      if (autoload) {
        console.debug('[SLOTS][LIFECYCLE][ONSET] onload');
        await onLoad();
      }
    } else {
      console.error('[SLOTS][LIFECYCLE][ONSET] NO CONTAINERS FOUND FOR PAGE');
      newRelicMetrics.reportError(BaxterError.LIFECYCLE_NO_CONTAINERS_FOUND_FOR_PAGE, { pageId });
    }
  } catch (error) {
    console.error('[SLOTS][LIFECYCLE][ONSET]', error);
    newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_SET_ERROR, { message: (error as Error).message });
  }
};

export const onSetPageParams = (params: TargetingParams) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONSETPAGEPARAMS]', params);
    State.setPageParams(params);
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONSETPAGEPARAMS]', e);
    newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_PAGE_PARAMS_ERROR, { message: (e as Error).message });
    throw e;
  }
};

export const onClear = () => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONCLEAR]');
    if (Interstitial?.isVisible?.()) {
      console.debug(`[SLOTS][LIFECYCLE][ONCLEAR] State.setInterstitialAction`);
      State.setInterstitialAction(async () => onClear());
      return;
    }

    Provider.Providers.forEach((providerModule) => {
      if (providerModule && providerModule.clear) {
        console.debug('[SLOTS][LIFECYCLE][ONCLEAR] Provider.clear');
        try {
          providerModule.clear();
        } catch (e) {
          console.error('[SLOTS][LIFECYCLE][ONCLEAR] Provider.clear error', e);
          newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_CLEAR_PROVIDER_ERROR, { message: (e as Error).message });
        }
      }
    });
    Object.keys(State.getSlots()).forEach((containerId) => {
      State.removeGeneralObserver(containerId);
      State.removeElementObservers(containerId);
      State.removeContainerTimers(containerId);
      State.clearContainerSet(containerId);
      Html.hideElement(Slot.getInnerId(containerId));
      Html.clearElement(containerId);
      Html.clearInlineStyles(containerId);
      Html.clearElement(`${containerId}-after`);
      Html.clearInlineStyles(`${containerId}-after`);
    });
    State.removeGeneralTimer(UNLOADED_SLOTS_TIMER_KEY);
    State.setSlots();
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONCLEAR]', e);
    newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_CLEAR_ERROR, { message: (e as Error).message });
  }
};

export const onContainerIntersection =
  (
    observerType:
      | Observers.intersectionGeneral
      | Observers.intersectionLazyLoad
      | Observers.intersectionSticky
      | Observers.intersectionAutoplay
  ) =>
  (entries: IntersectionObserverEntry[]) => {
    entries.forEach(async (entry) => {
      try {
        const userConsent = !Consent || !Consent.isPending();
        const containerId = (entry.target as HTMLElement).id;
        const slot = State.getSlot(containerId) || {};
        const prevVisible = slot?.status?.visible;

        if (!slot.status) {
          console.debug('[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION] SLOT NOT FOUND', containerId);
          return;
        }
        switch (observerType) {
          case Observers.intersectionGeneral:
          case Observers.intersectionLazyLoad:
            if (entry.isIntersecting) {
              slot.status.visible = true;
              slot.status.fold = null;

              console.debug('[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION] CONTAINER VISIBLE', containerId);
              const pageId = State.getPageId();
              const slotId = slot.id;

              if (userConsent) {
                if (
                  LazyLoad &&
                  LazyLoad.isVisibleType(pageId, containerId, slotId) &&
                  !prevVisible &&
                  !slot.filled &&
                  !slot[Features.STICKY]?.state?.sticky
                ) {
                  console.debug(
                    '[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION] LazyLoad.loadSlot',
                    pageId,
                    containerId,
                    slotId
                  );
                  await LazyLoad.loadSlot(pageId, containerId, slotId);
                } else if (slot.status.refreshPending) {
                  console.debug('[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION] Slot.set', pageId, containerId, slotId);
                  slot.status.refreshCount++;
                  await Slot.set(containerId, slot.params, false);
                }
              }
            } else {
              slot.status.visible = false;
              console.debug('[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION] CONTAINER HIDDEN', containerId);
              if (entry.boundingClientRect.top > 0) {
                slot.status.fold = 'below';
              } else {
                slot.status.fold = 'above';
              }
            }
            return;
          case Observers.intersectionSticky:
            console.debug('[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION] Sticky.onIntersection', entry, slot);
            Sticky.onIntersection(slot as StickySlot, entry);
            return;
          case Observers.intersectionAutoplay:
            console.debug('[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION] Provider.autoplay', entry, slot);
            Autoplay.onIntersection(slot as AutoplaySlot, entry);
            return;
          default:
            console.error(`[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION] unsupported observer type: ${observerType}`);
            throw new Error(`[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION] unsupported observer type: ${observerType}`);
        }
      } catch (e) {
        console.error('[SLOTS][LIFECYCLE][ONCONTAINERINTERSECTION]', e);
        newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_CONTAINER_INTERSECTION_ERROR, {
          message: (e as Error).message,
        });
      }
    });
  };

export const onContainerResize =
  (observerType: Observers.resizeSticky | Observers.resizeAutoplay) => (entries: ResizeObserverEntry[]) => {
    entries.forEach(async (entry) => {
      try {
        const containerId = entry.target.id;
        const slot = State.getSlot(containerId) || {};
        if (!slot.status) {
          console.debug('[SLOTS][LIFECYCLE][ONCONTAINERRESIZE] SLOT NOT FOUND', containerId);
          return;
        }
        switch (observerType) {
          case Observers.resizeSticky:
            console.debug('[SLOTS][LIFECYCLE][ONCONTAINERRESIZE] Sticky.onResize', entry, slot);
            Sticky.onResize(slot as StickySlot, entry);
            return;
          case Observers.resizeAutoplay:
            console.debug('[SLOTS][LIFECYCLE][ONCONTAINERRESIZE] Autoplay.onResize', entry, slot);
            Autoplay.onResize(slot as AutoplaySlot);
            return;
          default:
            console.error(`[SLOTS][LIFECYCLE][ONCONTAINERRESIZE] unsupported observer type: ${observerType}`);
            throw new Error(`[SLOTS][LIFECYCLE][ONCONTAINERRESIZE] unsupported observer type: ${observerType}`);
        }
      } catch (e) {
        console.error('[SLOTS][LIFECYCLE][ONCONTAINERRESIZE]', e);
        newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_CONTAINER_RESIZE_ERROR, { message: (e as Error).message });
      }
    });
  };

export const onResized = async () => {
  if (BreakpointRefresh) {
    await BreakpointRefresh.refreshSlots();
  }
};

export const onPageChanged = (pageId: string, params: Record<string, string>) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONPAGECHANGED]', pageId, params);
    const targetPage = Interstitial?.getTargetPage?.(pageId) || pageId;
    const interstitialId = `${State.getPageId()}#${targetPage}`;
    if (Interstitial?.readyToRender?.(interstitialId, targetPage)) {
      console.debug(`[SLOTS][LIFECYCLE][ONPAGECHANGED] State.setInterstitialAction`);
      State.setInterstitialAction(async () => onPageChanged(pageId, params));
      Interstitial.showModal(interstitialId);
      return;
    }
    const prevPageId = State.getPageId();
    console.debug(`[SLOTS][LIFECYCLE][ONPAGECHANGED] PAGE CHANGED: from '${prevPageId}' to '${pageId}'`, params);
    globalThis.Baxter.context.slotContainerRepository.clearAllNoFills();
    globalThis.Baxter.context.fillContainerMetrics.clear();
    State.setPage(pageId, params);
    State.setUserId();
    State.setSessionLong();
    State.setBreakpoint();
    onClear();
    Placeholder.applyToPage(pageId, params);
    if (Cxense) {
      const intervalId = setInterval(() => Cxense.sendPageViewEvent(pageId), 500);
      State.setCxenseIntervalId(intervalId);
    }
    if (Gemius) {
      Gemius.gemiusHit();
    }
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONPAGECHANGED]', e);
    newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_PAGE_CHANGED_ERROR, { message: (e as Error).message });
  }
};

export const onActiveChanged = async () => {
  const slots = State.getSlots();
  Object.keys(slots).map(async (containerId) => {
    const slot = slots[containerId];
    if (slot.status.visible && slot.status.refreshPending) {
      slot.status.refreshCount++;
      console.debug('[SLOTS][LIFECYCLE][ONACTIVECHANGED] Slot.set', containerId, slot.params);
      await Slot.set(containerId, slot.params, false);
    }
  });
};

export const onPageTrack = async () => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONPAGETRACK]');
    State.getPageTrackers().forEach((tracker) => tracker());
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONPAGETRACK]', e);
    newRelicMetrics.reportError(BaxterError.LIFECYCLE_ON_PAGE_TRACK_ERROR, { message: (e as Error).message });
  }
};
