import * as StringTransform from 'Helper/string/StringTransform';

export const isString = (string: unknown) => typeof string === 'string' || string instanceof String;

export const isNumeric = (string: string | unknown) => {
  if (typeof string !== 'string') return false;
  return !Number.isNaN(string) && !Number.isNaN(parseFloat(string));
};

export const parseMap = (stringMap: Record<string, string>[] = [], variablesMap = {}) =>
  StringTransform.mapFromVariables(stringMap, variablesMap);

export const base64Encode = (str: string) =>
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16))));

export const hashCode = (str: string) => {
  let hash = 0;
  let chr: number;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    /* eslint-disable no-bitwise */
    hash = (hash << 5) - hash + chr;
    hash |= 0;
    /* eslint-enable no-bitwise */
  }
  return hash;
};

export const removeSpaces = (string: string) => (isString(string) ? string.replace(/\s/g, '') : '');
