import * as CmdQueue from 'Helper/array/CmdQueue';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';
import { BaxterContext } from 'Domain/version/web/context/BaxterContext';
import { handleSlotRenderedEvent, SLOT_RENDERED_EVENT } from 'Domain/version/web/core/SlotRenderedEventListener';
import * as State from './core/State';
import * as Event from './core/Event';
import * as Provider from './core/Provider';
import * as LifeCycle from './core/Lifecycle';
import * as Polyfill from './core/Polyfill';
import * as Preview from './core/Preview';
import * as Interstitial from './provider/googleads/Interstitial';
import Consent from './feature/Consent';
import Style from './feature/Style';
import AdBlock from './feature/AdBlock';
import Cxense from './feature/Cxense';
import Scripts from './feature/Scripts';
import Gemius from './feature/Gemius';
import Sati from './feature/Sati';

globalThis.Baxter = globalThis.Baxter || {};
globalThis.Baxter.queue = globalThis.Baxter.queue || [];

if (!globalThis.Baxter.initialized) {
  /* eslint-disable no-undef */
  globalThis.Baxter.config = __CONFIG__;
  globalThis.Baxter.context = new BaxterContext(__CONFIG__);
  // todo: merge into config before runtime
  globalThis.Baxter.config.accountId = __ACCOUNT_ID__;
  globalThis.Baxter.config.appId = __APP_ID__;
  globalThis.Baxter.config.versionId = __VERSION_ID__;
  globalThis.Baxter.config.commitHash = __COMMIT_HASH__;
  globalThis.Baxter.config.environment = __ENVIRONMENT__;
  globalThis.Baxter.config.cdnDomain = __CDN_DOMAIN__;
  globalThis.Baxter.config.apiDomain = __API_DOMAIN__;
  // todo: set in 'baxter-native' provider config (not env var)??
  globalThis.Baxter.config.adsDomain = __ADS_DOMAIN__;
  /* eslint-disable no-undef */

  globalThis.Baxter.state = {
    providers: [],
    page: {
      id: null,
      params: {},
    },
    slots: {},
    user: {
      observers: {},
      timers: {
        generals: [],
        containers: {},
      },
      cxense: {},
      sati: {
        init: false,
      },
      active: true,
      consent: false,
    },
    app: {
      breakpoints: [],
      set: {},
    },
    trackers: [],
  };

  newRelicMetrics.reportMetric(BaxterMetric.SCRIPT_INITIALIZED, {});

  globalThis.Baxter.consent = Consent
    ? Consent.setUserConsent
    : async () => {
        console.debug('[SLOTS][CONSENT][SETUSERCONSENT] disabled in slots so do nothing');
      };
  globalThis.Baxter.slots = {};
  globalThis.Baxter.page = LifeCycle.onPageChanged;
  globalThis.Baxter.set = LifeCycle.onSet;
  globalThis.Baxter.setAfterLoaded = LifeCycle.onSetAfterLoaded;
  globalThis.Baxter.clear = LifeCycle.onClear;
  globalThis.Baxter.track = LifeCycle.onPageTrack;
  globalThis.Baxter.setPageParams = LifeCycle.onSetPageParams;

  (async () => {
    try {
      console.info('[SLOTS][INITJS] INIT SCRIPT LOADED');
      window.addEventListener(SLOT_RENDERED_EVENT, handleSlotRenderedEvent);
      if (await Preview.init()) {
        console.info('[SLOTS][INITJS] PREVIEW SCRIPT LOADED');
      } else if (globalThis.Baxter.config.app.enabled) {
        console.info('[SLOTS][INITJS] INITIALIZING', globalThis.Baxter.config);
        globalThis.Baxter.initialized = true;

        if (Style) Style.bootstrap();
        Polyfill.bootstrap();
        if (AdBlock) AdBlock.bootstrap();
        if (Consent) Consent.bootstrap();
        // TODO: remove cxense after confirmation of SATI feature working properly
        if (Cxense) Cxense.bootstrap();
        if (Scripts) Scripts.bootstrap();
        if (Gemius) Gemius.bootstrap();
        State.bootstrap();
        Provider.bootstrap();
        Event.bootstrap();
        if (Sati) Sati.bootstrap();
        try {
          if (Interstitial && typeof Interstitial?.bootstrap === 'function') {
            Interstitial?.bootstrap();
          } else {
            console.debug('[SLOTS][INITJS] Interstitial not available');
          }
        } catch (e) {
          console.error('[SLOTS][INITJS] Interstitial init error', e);
          newRelicMetrics.reportError(BaxterError.INTERSTITIAL_INIT_ERROR, { message: (e as Error).message });
        }
        await CmdQueue.init(globalThis.Baxter.queue);
      }
    } catch (e) {
      console.error('[SLOTS][INITJS]', e);
      newRelicMetrics.reportError(BaxterError.SCRIPT_ERROR, { message: (e as Error).message });
    }
  })();
}
