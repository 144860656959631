import * as State from 'Domain/version/web/core/State';
import { NewRelicMetrics } from 'Helper/metrics/NewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';

export class BaxterNewRelicMetrics {
  private noSampling: Record<BaxterError | BaxterMetric, boolean>;

  constructor(private newRelicMetrics: NewRelicMetrics) {
    this.noSampling = {} as Record<BaxterError | BaxterMetric, boolean>;
    this.noSampling[BaxterError.GOOGLEADS_COMMAND_ERROR] = true;
    this.noSampling[BaxterMetric.INTERSTITIAL_INVALID_FREQUENCY_CAP] = true;
  }

  sendMetric(name, parameters, skipSampling = false) {
    if (State && typeof State.getUserId === 'function') {
      parameters.userId = State.getUserId();
    }
    if (globalThis.Baxter?.state?.featureFlags) {
      parameters.featureFlag = globalThis.Baxter?.state?.featureFlags;
    }
    if (globalThis.Baxter?.state?.abTest) {
      parameters.abTest = globalThis.Baxter?.state?.abTest;
    }
    const baxterizedParameters = this.addBaxterPrefix(parameters);
    if (globalThis.Baxter && globalThis.Baxter.config) {
      const { accountId, versionId, appId, commitHash } = globalThis.Baxter.config;
      const baxterizedConfigInfo = this.addBaxterPrefix({ accountId, versionId, appId, commitHash });
      this.newRelicMetrics.sendMetric(name, { ...baxterizedConfigInfo, ...baxterizedParameters }, skipSampling);
    } else {
      this.newRelicMetrics.sendMetric(name, baxterizedParameters, skipSampling);
    }
  }

  reportMetric(name: BaxterMetric, parameters) {
    try {
      this.sendMetric(name, { ...parameters }, this.noSampling[name]);
    } catch (err) {
      console.error(err);
    }
  }

  reportError(errorCode: BaxterError, parameters) {
    try {
      this.sendMetric('BaxterError', { errorCode, ...parameters }, this.noSampling[errorCode]);
    } catch (err) {
      console.error(err);
    }
  }

  addBaxterPrefix(parameters) {
    const baxterized: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(parameters)) {
      baxterized[`baxter${key.charAt(0).toUpperCase() + key.slice(1)}`] = value;
    }
    return baxterized;
  }
}

export default new BaxterNewRelicMetrics(new NewRelicMetrics(3000, 10));
