import * as Html from 'Helper/browser/Html';
import * as Slot from 'Domain/version/web/core/Slot';
import * as Cookie from 'Helper/browser/Cookie';

const SCRIPT_ID = 'baxter-script-slots';
const STYLE_ID = 'baxter-link-slots';

export const webpackExclude = (config) => {
  const settings = config.slots?.settings?.styling || {};
  const settingsDefault = settings._ || {};
  return !(Object.values(settingsDefault).find((item) => !!item) || Object.values(settings).find((item) => !!item));
};

const bootstrap = () => {
  const { accountId, appId, cdnDomain } = globalThis.Baxter.config;
  const { versionId } = globalThis.Baxter.config;
  const appPath = `${cdnDomain}/${accountId}/${appId}`;
  if (isRelease(SCRIPT_ID)) {
    Html.addStyleToHead(STYLE_ID, `${appPath}/release/init.css`, true);
  } else {
    Html.addStyleToHead(STYLE_ID, `${appPath}/${versionId}/init.css`, true);
  }

  // TODO: EUADS-3503: remove after test
  const laquesisCookie = Cookie.get('laquesis') || '';
  if (Baxter.config.accountId === 'olxbg' && laquesisCookie.includes('euads-3503@b')) {
    Html.addStyleToHead(
      `baxter-style-init-placeholder`,
      `${cdnDomain}/${accountId}/${appId}/${versionId}/init-placeholder.css`
    );
  }
  // TODO: remove ^
};

const getClass = (pageId, containerId, slotId) => {
  const settings = globalThis.Baxter.config.slots?.settings;
  if (settings?.styling) {
    const slotStyleKey = Slot.getKeyById(settings.styling, pageId, containerId, slotId);
    if (slotStyleKey) {
      return `baxter-${slotStyleKey}`;
    }
  }
  return null;
};

const addClass = (pageId, containerId, slotId) => {
  const className = getClass(pageId, containerId, slotId);
  if (className) {
    const container = Html.getElementById(Slot.getInnerId(containerId));
    Html.addClass(container, className);
  }
};

const removeClass = (pageId, containerId, slotId) => {
  const className = getClass(pageId, containerId, slotId);
  if (className) {
    const container = Html.getElementById(Slot.getInnerId(containerId));
    Html.removeClass(container, className);
  }
};

const isRelease = (scriptId) => (Html.getElementById(scriptId) as HTMLScriptElement)?.src.includes('/release/');

export default {
  bootstrap,
  addClass,
  removeClass,
};
