import * as Strings from 'Helper/string/String';
import * as Objects from 'Helper/object/Object';
import * as Html from 'Helper/browser/Html';
import * as State from 'Domain/version/web/core/State';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';
import {
  impressionCallback,
  renderedCallback,
  requestedCallback,
  responseReceivedCallback,
} from 'Domain/version/web/provider/googleads/GoogleAdsEventsCallbacks';
import { Providers } from 'Domain/version/web/config/Providers';
import { Slot } from 'Types/Slot';
import { GoogleAdsConfig } from 'Types/ProviderSettings/GoogleAds';
import { Config } from 'Types/Config';
import { transformTargeting } from '../TransformTargeting';
import * as Interstitial from './Interstitial';
import Bidders from './bidders/GoogleAdsBidders';

export const id = Providers.GOOGLE_ADS;

export const webpackExclude = (config: Config) =>
  // @ts-ignore
  !(Object.values(config.slots.provider._).includes(id) || Object.values(config.slots.provider).includes(id));

export const init = () => {
  console.info('[SLOTS][GOOGLEADS][INIT]');
  globalThis.googletag = globalThis.googletag || { cmd: [] };
  globalThis.googletag.cmd = globalThis.googletag.cmd || [];

  if (Bidders) {
    Bidders.init();
  }
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEADS][DEPENDENCIES]');
  const dependencyList = [
    {
      id: 'gpt',
      url: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
    },
  ];
  if (Bidders) {
    dependencyList.push(...Bidders.dependencies());
  }
  return {
    id,
    dependencies: dependencyList,
  };
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEADS][LOADED]');
  const providerConfig = globalThis.Baxter.config.providers[id] || {};
  const googleSettings: Record<string, unknown> = providerConfig.settings || {};
  const { googletag } = globalThis;
  console.debug('[SLOTS][GOOGLEADS][LOADED]', googletag, providerConfig);
  if (googletag) {
    googletag.cmd.push(() => {
      try {
        if (Strings.isString(googleSettings.adsenseBackgroundColor)) {
          console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().set');
          googletag.pubads().set('adsense_background_color', googleSettings.adsenseBackgroundColor);
        }
        if (googleSettings.collapseEmptyDivs === 'after') {
          console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().collapseEmptyDivs');
          googletag.pubads().collapseEmptyDivs();
        }
        if (googleSettings.collapseEmptyDivs === 'before') {
          console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().collapseEmptyDivs true');
          googletag.pubads().collapseEmptyDivs(true);
        }
        if (googleSettings.singleRequest === true) {
          console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().enableSingleRequest');
          googletag.pubads().enableSingleRequest();
        }
        console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.pubads().disableInitialLoad');
        googletag.pubads().disableInitialLoad();
        console.debug('[SLOTS][GOOGLEADS][LOADED] googletag.enableServices');
        googletag.enableServices();
      } catch (e) {
        console.error('[SLOTS][GOOGLEADS][LOADED]', e);
        newRelicMetrics.reportError(BaxterError.GOOGLEADS_COMMAND_ERROR, {
          command: '[LOADED]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEADS][LOADED] googletag not defined`);
    newRelicMetrics.reportError(BaxterError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[LOADED]' });
  }

  if (Bidders) {
    Bidders.loaded();
  }
};

export const transform = async (pageId, containerId, slotId, params) => {
  console.info('[SLOTS][GOOGLEADS][TRANSFORM]', pageId, containerId, slotId, params);
  let slot = {
    path: [],
    targeting: {},
    sizes: [],
    styling: {},
    interstitial: {},
  } as Record<string, unknown>;
  const providerConfig = globalThis.Baxter.config.providers[id]?.settings;
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] as GoogleAdsConfig;

  transformTargeting(slot, providerSettings, providerConfig, pageId, containerId, slotId, params);
  if (providerSettings?.path) {
    const { accountId } = providerConfig;
    const pathPrefixMap = providerConfig.pathPrefix;
    const pathPrefix = Strings.parseMap(pathPrefixMap || [], params);
    const pathSettings =
      globalThis.Baxter.context.configurationService.getById(providerSettings?.path, pageId, containerId, slotId) || {};
    const path = Strings.parseMap(pathSettings.map || [], params);
    slot.path = pathPrefix ? `/${accountId}/${pathPrefix}/${path}` : `/${accountId}/${path}`;
  }
  if (providerSettings?.sizes) {
    const defaultSizes = [{ viewport: [1, 1], slot: [[1, 1]] }];
    const sizeSettings =
      globalThis.Baxter.context.configurationService.getById(providerSettings?.sizes, pageId, containerId, slotId) ||
      {};
    slot.sizes = (sizeSettings.map || defaultSizes).map((size) => {
      if (!size.slot || size.slot.length === 0) size.slot = defaultSizes[0].slot;
      size.slot.forEach((item, index) => {
        if (Strings.isString(item) && item.includes('x')) {
          const widthHeight = item.split('x');
          const isIntegers = widthHeight.every((element) => Strings.isNumeric(element));
          if (isIntegers) {
            size.slot[index] = widthHeight.map((element) => parseInt(element, 10));
          }
        }
      });
      return size;
    });
    (slot.sizes as number[]).reverse();
  }

  slot.interstitial =
    globalThis.Baxter.context.configurationService.getById(
      providerSettings?.interstitial,
      pageId,
      containerId,
      slotId
    ) || {};

  if (Bidders && Bidders.enabledSomeBidderForSlot(containerId, slotId)) {
    slot = { ...slot, ...(await Bidders.transform(pageId, containerId, slotId, params)) };
  }
  return slot;
};

export const create = async (slot: Slot) =>
  new Promise((resolve, reject) => {
    console.info('[SLOTS][GOOGLEADS][CREATE]', slot);
    console.debug('[SLOTS][GOOGLEADS][CREATE]', slot.containerId, slot.id);
    const providerConfig = globalThis.Baxter.config.providers[id]?.settings || {};
    if (slot.interstitial?.enabled) {
      console.debug(`[SLOTS][GOOGLEADS][CREATE] ${slot.containerId} ${slot.id} Interstitial.insertModal`);
      Interstitial?.insertModal?.(slot.pageId, slot);
    }
    const Google = {
      create: (googleSlot) => {
        const { googletag } = globalThis;
        if (googletag) {
          googletag.cmd.push(() => {
            console.info(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} {...} = slot`);
            const { containerId, pageId, path, innerId, id: slotId, interstitial, status } = googleSlot;
            const sizes = googleSlot.sizes?.[0]?.slot;
            try {
              console.debug(
                `[SLOTS][GOOGLEADS][CREATE] ${containerId} ${slotId} before googletag.defineSlot`,
                path,
                sizes,
                innerId,
                Html.getElementById(containerId),
                Html.getElementById(innerId)
              );
              const external = googletag.defineSlot(path, sizes, innerId);
              console.debug(
                `[SLOTS][GOOGLEADS][CREATE] ${containerId} ${slotId} after googletag.defineSlot`,
                path,
                sizes,
                innerId,
                Html.getElementById(containerId),
                Html.getElementById(innerId)
              );
              if (external) {
                console.debug(
                  `[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} external.addService`
                );
                external.addService(googletag.pubads());
                setSlotSizes(googleSlot.sizes || [], external);
                setSlotTargeting(googleSlot.targeting || {}, external);

                console.debug(
                  `[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} googletag.pubads().addEventListener`
                );
                googletag.pubads().addEventListener('slotRequested', requestedCallback(interstitial, path));
                googletag
                  .pubads()
                  .addEventListener('slotResponseReceived', responseReceivedCallback(interstitial, path));
                googletag
                  .pubads()
                  .addEventListener(
                    'slotRenderEnded',
                    renderedCallback(
                      external,
                      googleSlot,
                      innerId,
                      containerId,
                      pageId,
                      interstitial,
                      slotId,
                      status,
                      path
                    )
                  );
                googletag
                  .pubads()
                  .addEventListener(
                    'impressionViewable',
                    impressionCallback(external, containerId, slotId, pageId, path, googleSlot)
                  );
                if (
                  (!providerConfig.singleRequest || googleSlot.status.loaded) &&
                  (!googleSlot.status.lazyLoad || googleSlot.status.visible)
                ) {
                  console.debug(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} refresh`);
                  refresh([{ ...googleSlot, external }])
                    .then(() => {
                      resolve(external);
                    })
                    .catch((err) => {
                      reject(err);
                    });
                  return;
                }
                resolve(external);
              } else {
                console.error(
                  `[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} could not get external`
                );
                newRelicMetrics.reportError(BaxterError.GOOGLEADS_NO_EXTERNAL, {
                  providerId: id,
                  slotId,
                  containerId,
                  pageId,
                  path,
                });
                reject(new Error('[SLOTS][GOOGLEADS] could not get external'));
              }
            } catch (e) {
              console.error(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id}`, e);
              newRelicMetrics.reportError(BaxterError.GOOGLEADS_COMMAND_ERROR, {
                command: '[CREATE]',
                message: (e as Error).message,
                slotId,
                containerId,
                sizes,
                pageId,
                path,
                interstitial,
              });
              reject(e);
            }
          });
        } else {
          console.error(`[SLOTS][GOOGLEADS][CREATE] ${googleSlot.containerId} ${googleSlot.id} googletag not defined`);
          newRelicMetrics.reportError(BaxterError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[CREATE]' });
          reject(new Error('[SLOTS][GOOGLEADS][CREATE] googletag not defined'));
        }
      },
    };

    if (Bidders && Bidders.enabledSomeBidderForSlot(slot.containerId, slot.id)) {
      console.debug(`[SLOTS][GOOGLEADS][CREATE] ${slot.containerId} ${slot.id} Bidders.create`);
      Bidders.create(slot, Google.create);
    } else {
      console.debug(`[SLOTS][GOOGLEADS][CREATE] ${slot.containerId} ${slot.id} Google.create`);
      Google.create(slot);
    }
  });

const setSlotSizes = (sizes, external) => {
  console.info('[SLOTS][GOOGLEADS][SETSLOTSIZES]', sizes, external);
  const { googletag } = globalThis;
  if (googletag) {
    const map = googletag.sizeMapping();
    sizes.forEach((size) => {
      if (size.viewport) {
        map.addSize(size.viewport, size.slot || []);
      }
    });
    const mapping = map.build();
    external.defineSizeMapping(mapping);
  } else {
    console.error(`[SLOTS][GOOGLEADS][SETSLOTSIZES]`);
    newRelicMetrics.reportError(BaxterError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[SETSLOTSIZES]' });
  }
};

const setSlotTargeting = (keyValues, external) => {
  console.info('[SLOTS][GOOGLEADS][SETSLOTTARGETING]', keyValues, external);
  const { googletag } = globalThis;
  if (googletag) {
    for (const key of Object.keys(keyValues)) {
      const val = keyValues[key];
      const valueToCheck = googletag.pubads().getTargeting(key);
      if (valueToCheck.length === 0) {
        external.setTargeting(key, val);
      } else if (valueToCheck.length === 1 && !Array.isArray(val) && valueToCheck[0] !== val) {
        external.setTargeting(key, val);
      } else if (Array.isArray(val) && val.filter((x) => valueToCheck.includes(x)).length !== val.length) {
        external.setTargeting(key, val);
      }
    }
  } else {
    console.error(`[SLOTS][GOOGLEADS][SETSLOTTARGETING]`);
    newRelicMetrics.reportError(BaxterError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[SETSLOTTARGETING]' });
  }
};

const setPageTargeting = (targetingMap) => {
  console.info('[SLOTS][GOOGLEADS][SETPAGETARGETING]', targetingMap);
  if (Array.isArray(targetingMap)) {
    const params = State.getPageParams();
    const ranges = globalThis.Baxter.config.app?.ranges;
    const targeting = Objects.parseMap(targetingMap || [], params, ranges);
    Object.keys(targeting).forEach((key) => globalThis.googletag.pubads().setTargeting(key, targeting[key]));
    if (Bidders) {
      Bidders.setTargeting(targeting);
    }
  }
};

export const load = async (slots: Slot[] = []) => {
  console.info('[SLOTS][GOOGLEADS][LOAD]', slots);
  const providerSettings = globalThis.Baxter.config.providers[id] || {};
  const googleSettings = providerSettings.settings || {};
  const { googletag } = globalThis;
  if (googletag) {
    const singleRequest = googleSettings.singleRequest || false;
    if (singleRequest) {
      let slotsToLoad = slots.filter((slot) => !slot.status.lazyLoad || slot.status.visible);

      const interstitialSlot = slotsToLoad.find((slot) => slot.interstitial?.enabled);
      if (
        interstitialSlot?.params.abTest.includes(Interstitial.interstitialAbTests.INTERSTITIAL_AD_WITHOUT_PRELOADING)
      ) {
        slotsToLoad = slotsToLoad.filter((slot) => slot.id !== interstitialSlot.id);
        Interstitial?.storeInState?.(interstitialSlot);
      }
      console.debug('[SLOTS][GOOGLEADS][LOAD] Bidders.getSlotsByType', slotsToLoad);
      const slotsByType = Bidders
        ? Bidders.getSlotsByType(slotsToLoad)
        : { bidders: [], google: slotsToLoad, byBidder: {} };
      console.debug('[SLOTS][GOOGLEADS][LOAD] slotsByType', slotsByType);
      if (Bidders && slotsByType.bidders.length > 0) {
        console.debug('[SLOTS][GOOGLEADS][LOAD] Bidders.load', slotsByType);
        Bidders.load(slotsByType);
      } else {
        const slotsToRefresh = slotsByType.google.filter((slot) => slot.external && Html.getElementById(slot.innerId));
        const externals = slotsToRefresh.map((slot) => slot.external);
        if (externals.length) {
          googletag.cmd.push(() => {
            try {
              console.debug('[SLOTS][GOOGLEADS][LOAD] googletag.pubads().refresh(...)', slots);
              googletag.pubads().refresh(externals);
            } catch (e) {
              console.error('[SLOTS][GOOGLEADS][LOAD]', e);
              newRelicMetrics.reportError(BaxterError.GOOGLEADS_COMMAND_ERROR, {
                command: '[LOAD]',
                message: (e as Error).message,
              });
              throw e;
            }
          });
        }
      }

      const filledSlots = slotsToLoad.filter((slot) => slot.external && Html.getElementById(slot.innerId));
      for (const slot of filledSlots) {
        slot.filled = true;
      }
      console.debug('[SLOTS][GOOGLEADS][LOAD] filledSlots', filledSlots);
    }
    return true;
  }
  console.error(`[SLOTS][GOOGLEADS][LOAD]`);
  newRelicMetrics.reportError(BaxterError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[LOAD]' });

  return false;
};

export const refresh = async (slots: Slot[] = []) => {
  console.info('[SLOTS][GOOGLEADS][REFRESH]', slots);
  const { googletag } = globalThis;
  if (googletag) {
    if (slots.length > 0) {
      console.debug('[SLOTS][GOOGLEADS][REFRESH] Bidders.getSlotsByType', slots);
      const slotsByType = Bidders ? Bidders.getSlotsByType(slots) : { bidders: [], google: slots, byBidder: {} };
      if (Bidders && slotsByType.bidders.length > 0) {
        console.debug('[SLOTS][GOOGLEADS][REFRESH] Bidders.refresh', slotsByType.bidders);
        Bidders.refresh(slotsByType.bidders);
      }
      const googleSlots = slotsByType.google;
      if (googleSlots.length > 0) {
        const innerIds = googleSlots.map((slot) => slot.innerId);
        // @ts-ignore
        const changeCorrelator = !googleSlots.find((slot) => slot.status.lazyLoad && !slot.status.filled);
        const slotsToRefresh = googleSlots.filter((slot) => slot.external);
        const externals = slotsToRefresh.map((slot) => slot.external);
        googletag.cmd.push(() => {
          try {
            console.debug('[SLOTS][GOOGLEADS][REFRESH] googletag.display', innerIds);
            innerIds.forEach((innerId) => googletag.display(innerId));
            console.debug('[SLOTS][GOOGLEADS][REFRESH] googletag.pubads().refresh(...)', slots, changeCorrelator);
            googletag.pubads().refresh(externals, { changeCorrelator });
          } catch (e) {
            console.error('[SLOTS][GOOGLEADS][REFRESH]', e);
            newRelicMetrics.reportError(BaxterError.GOOGLEADS_COMMAND_ERROR, {
              command: '[REFRESH]',
              message: (e as Error).message,
            });
            throw e;
          }
        });
      }

      for (const slot of slots) {
        slot.filled = true;
      }
      console.debug('[SLOTS][GOOGLEADS][REFRESH] filledSlots', slots);
    }
    return true;
  }
  console.error(`[SLOTS][GOOGLEADS][REFRESH]`);
  newRelicMetrics.reportError(BaxterError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[REFRESH]' });

  return false;
};

export const remove = (slots: Slot[] = []) => {
  console.info('[SLOTS][GOOGLEADS][REMOVE]', slots);
  const { googletag } = globalThis;
  if (googletag) {
    if (Bidders) {
      console.debug('[SLOTS][GOOGLEADS][REMOVE] Bidders.remove', slots);
      Bidders.remove(slots);
    }
    googletag.cmd.push(() => {
      try {
        console.debug('[SLOTS][GOOGLEADS][REMOVE] googletag.destroySlots', slots);
        const destroyed = googletag.destroySlots(slots.map((slot) => slot.external));
        console.debug(`[SLOTS][GOOGLEADS][REMOVE] googletag.destroySlots() result ${destroyed}`);
        if (!destroyed) {
          newRelicMetrics.reportMetric(BaxterMetric.GOOGLEADS_DESTROY_SLOTS_IN_REMOVE_DESTROYED_NOTHING, {});
        }
      } catch (e) {
        console.error('[SLOTS][GOOGLEADS][REMOVE]', e);
        newRelicMetrics.reportError(BaxterError.GOOGLEADS_COMMAND_ERROR, {
          command: '[REMOVE]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
    return true;
  }
  console.error(`[SLOTS][GOOGLEADS][REMOVE]`);
  newRelicMetrics.reportError(BaxterError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[REMOVE]' });

  return false;
};

export const clear = () => {
  console.info('[SLOTS][GOOGLEADS][CLEAR]');
  const providerSettings = globalThis.Baxter.config.providers[id] || {};
  const googleSettings = providerSettings.settings || {};
  if (Interstitial && typeof Interstitial?.clear === 'function') {
    console.debug('[SLOTS][GOOGLEADS][CLEAR] Interstitial.clear');
    Interstitial.clear();
  }
  if (Bidders) {
    console.debug('[SLOTS][GOOGLEADS][CLEAR] Bidders.clear');
    Bidders.clear();
  }
  const { googletag } = globalThis;
  if (googletag) {
    googletag.cmd.push(() => {
      try {
        console.debug('[SLOTS][GOOGLEADS][CLEAR] googletag.pubads().clearTargeting');
        googletag.pubads().clearTargeting();
        const destroyed = googletag.destroySlots();
        console.debug(`[SLOTS][GOOGLEADS][CLEAR] googletag.destroySlots() result ${destroyed}`);
        if (!destroyed) {
          newRelicMetrics.reportMetric(BaxterMetric.GOOGLEADS_DESTROY_SLOTS_IN_CLEAR_DESTROYED_NOTHING, {});
        }
        setPageTargeting(googleSettings.targeting);
      } catch (e) {
        console.error('[SLOTS][GOOGLEADS][CLEAR]', e);
        newRelicMetrics.reportError(BaxterError.GOOGLEADS_COMMAND_ERROR, {
          command: '[CLEAR]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
    return true;
  }
  console.error(`[SLOTS][GOOGLEADS][CLEAR]`);
  newRelicMetrics.reportError(BaxterError.GOOGLEADS_NO_GOOGLE_TAG, { command: '[CLEAR]' });

  return false;
};
