import { BaxterNewRelicMetrics } from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';
import { Providers } from 'Domain/version/web/config/Providers';
import { ConfigurationService } from 'Domain/version/web/configuration/ConfigurationService';

/**
 * @deprecated
 * Extend Slot, Lifecycle, etc. entities instead or create a new one.
 * Use globalThis.Baxter.state as storage.
 */
export class ContainerInformation {
  private slots: { slotId: string; providerId: Providers }[];

  constructor(
    private pageId: string,
    private containerId: string,
    private isFallbackEnabled: boolean
  ) {
    this.slots = [];
  }

  addSlotAttempt(slotId: string, providerId: Providers) {
    this.slots.push({ slotId, providerId });
  }
}

/**
 * @deprecated
 * Extend Slot, Lifecycle, etc. entities instead or create a new one.
 * Use globalThis.Baxter.state as storage.
 */
export class FillContainerMetrics {
  private containers: Record<string, ContainerInformation>;

  constructor(
    private configService: ConfigurationService,
    private newRelicMetrics: BaxterNewRelicMetrics
  ) {
    this.containers = {};
  }

  clear() {
    this.containers = {};
  }

  registerNoMoreSlots(pageId, containerId) {
    this.registerFill(this.getContainerInformation(pageId, containerId), {}, true);
  }

  registerSlotResult(pageId, containerId, slotId, status, providerId, isEmpty) {
    const containerInformation = this.getContainerInformation(pageId, containerId);
    containerInformation.addSlotAttempt(slotId, providerId);
    if (this.isFinalStep(isEmpty, containerInformation)) {
      this.registerFill(containerInformation, status, isEmpty);
    }
  }

  registerFill(containerInformation, status, isEmpty) {
    const attempts = containerInformation.slots.map((slot) => `${slot.slotId}|${slot.providerId}`).join(',');
    const { pageId, containerId, isFallbackEnabled } = containerInformation;
    const lastSlot = containerInformation.slots.pop() || { slotId: 'empty', providerId: 'empty' };
    const { slotId, providerId } = lastSlot;
    this.newRelicMetrics.reportMetric(BaxterMetric.AD_RENDERED, {
      pageId,
      containerId,
      slotId,
      attemptsNumber: containerInformation.slots.length,
      attempts,
      isEmpty,
      isVisible: status?.visible,
      fold: status?.fold,
      scroll: window.scrollY,
      providerId,
      isFallbackEnabled,
    });
  }

  isFinalStep(isEmpty, containerInformation) {
    return (isEmpty && !containerInformation.isFallbackEnabled) || !isEmpty;
  }

  getContainerInformation(pageId, containerId) {
    if (!this.containers[`${pageId}|${containerId}`]) {
      this.containers[`${pageId}|${containerId}`] = new ContainerInformation(
        pageId,
        containerId,
        this.configService.isFallbackEnabledFor(pageId, containerId)
      );
    }
    return this.containers[`${pageId}|${containerId}`];
  }
}
